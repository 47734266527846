import React, { useEffect, useState } from 'react'
import {
  CreditCard,
} from "lucide-react"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import { useAuth } from "@/hooks/useAuth"
import { supabase } from "@/lib/supabase"
import { getStoredAuthData, storeAuthData } from "@/lib/authStore"

export default function OrderDetails({ order }) {
  const { session } = useAuth()
  const [customerData, setCustomerData] = useState(null)

  useEffect(() => {
    const fetchCustomerData = async () => {
      if (session?.user) {
        // First check if we have the data in local storage
        const storedData = getStoredAuthData()
        if (storedData.profileData) {
          setCustomerData(storedData.profileData)
          return
        }

        try {
          // If no stored data, fetch from Supabase
          const { data, error } = await supabase
            .from("profiles")
            .select("*")
            .eq("user_id", session.user.id)
            .single()

          if (!error && data) {
            setCustomerData(data)
            // Store the fetched data for future use
            storeAuthData({ profileData: data })
          }
        } catch (error) {
          console.error('Error fetching customer data:', error)
        }
      }
    }

    fetchCustomerData()
  }, [session])

  return (
    <Card className="overflow-hidden shadow-lg">
      <CardHeader className="flex flex-row items-start bg-muted/50">
        <div className="grid gap-0.5">
          <CardTitle className="group flex items-center gap-2 text-lg">
            {order.item.name}
          </CardTitle>
          <CardDescription>With {order.doctor}</CardDescription>
        </div>
      </CardHeader>
      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Order Details</div>
          <ul className="grid gap-3">
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Service</span>
              <span>${order.item.price.toFixed(2)}</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Service Fee</span>
              <span>${order.serviceFee.toFixed(2)}</span>
            </li>
          </ul>
          <Separator className="my-2" />
          <ul className="grid gap-3">
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Subtotal</span>
              <span>${(order.item.price + order.serviceFee).toFixed(2)}</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">Tax</span>
              <span>${order.tax.toFixed(2)}</span>
            </li>
            <li className="flex items-center justify-between font-semibold">
              <span className="text-muted-foreground">Total</span>
              <span>${order.total.toFixed(2)}</span>
            </li>
          </ul>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Appointment Location</div>
          <div className="text-muted-foreground">
            <address>
              <a 
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${order.appointment.address}, ${order.appointment.cityStateZip}`)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline grid gap-0.5 not-italic"
              >
                <span>{order.appointment.address}</span>
                <span>{order.appointment.cityStateZip}</span>
              </a>
            </address>
          </div>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Customer Information</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Customer</dt>
              <dd>
                {customerData ? `${customerData.first_name} ${customerData.last_name}` : 'Loading...'}
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Email</dt>
              <dd>
                <a href={`mailto:${customerData?.email || ''}`}>
                  {customerData?.email || 'Loading...'}
                </a>
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Phone</dt>
              <dd>
                <a href={`tel:${customerData?.phone_number || ''}`}>
                  {customerData?.phone_number || 'Loading...'}
                </a>
              </dd>
            </div>
          </dl>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Payment Information</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="flex items-center gap-1 text-muted-foreground">
                <CreditCard className="h-4 w-4" />
                {order.payment.cardType}
              </dt>
              <dd>{order.payment.cardNumber}</dd>
            </div>
          </dl>
        </div>
      </CardContent>
    </Card>
  )
}
