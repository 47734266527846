import React, { useState, useEffect } from "react";
import SearchResults from "./SearchResults";
import LoadingSpinner from "@/components/common/Loader";

const SearchContainer = ({ initialSearchParams }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialSearchParams.results && initialSearchParams.results.length > 0) {
      setSearchResults(initialSearchParams.results);
    }
  }, [initialSearchParams]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background">
      <SearchResults results={searchResults} zipcode={initialSearchParams.place} />
    </div>
  );
};

export default SearchContainer;