import { useState, useEffect } from "react"
import { supabase } from "@/lib/supabase"
import TransactionsTable from "@/components/hcp/Transactions"

export default function Transactions() {
  const [stats, setStats] = useState({
    totalRevenue: 0,
    totalTransactions: 0,
    averageTransaction: 0
  })

  useEffect(() => {
    async function fetchTransactionStats() {
      const { data: { user } } = await supabase.auth.getUser()
      
      if (user) {
        const { data, error } = await supabase
          .from('transactions')
          .select('amount')
          .eq('provider_id', user.id)

        if (!error && data) {
          const totalRevenue = data.reduce((sum, transaction) => sum + transaction.amount, 0)
          const totalTransactions = data.length
          const averageTransaction = totalTransactions > 0 ? totalRevenue / totalTransactions : 0

          setStats({
            totalRevenue,
            totalTransactions,
            averageTransaction
          })
        }
      }
    }

    fetchTransactionStats()
  }, [])

  return (
    <>
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="p-4 rounded-lg bg-card shadow">
          <h3 className="font-semibold text-lg text-slate-700">Total Revenue</h3>
          <p className="text-2xl font-bold text-slate-700">${stats.totalRevenue.toFixed(2)}</p>
        </div>
        <div className="p-4 rounded-lg bg-card shadow">
          <h3 className="font-semibold text-lg text-slate-700">Total Transactions</h3>
          <p className="text-2xl font-bold text-slate-700">{stats.totalTransactions}</p>
        </div>
        <div className="p-4 rounded-lg bg-card shadow">
          <h3 className="font-semibold text-lg text-slate-700">Average Transaction</h3>
          <p className="text-2xl font-bold text-slate-700">${stats.averageTransaction.toFixed(2)}</p>
        </div>
      </div>
      <TransactionsTable />
    </>
  )
}