import { Switch } from "@/components/ui/switch"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { CalendarIcon } from "lucide-react"

export function AppointmentsCard() {
  return (
    <Card className="h-[350px] relative">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle>Appointments</CardTitle>
        <Button variant="outline" size="icon" className="h-8 w-8">
          <CalendarIcon className="h-4 w-4" />
        </Button>
      </CardHeader>
      <CardContent>
        <div className="space-y-4 mt-4">
          <p className="text-sm font-medium">You have 5 upcoming appointments this week</p>
          <div>
            <p className="text-xs text-muted-foreground mb-2">The closest 5 upcoming appointments:</p>
            <ul className="space-y-1">
              {[
                { id: 1, patient: "John Doe", time: "09:00 AM" },
                { id: 2, patient: "Jane Smith", time: "11:30 AM" },
                { id: 3, patient: "Bob Johnson", time: "02:00 PM" },
                { id: 4, patient: "Alice Brown", time: "04:30 PM" },
                { id: 5, patient: "Charlie Davis", time: "06:00 PM" },
              ].map((appointment) => (
                <li key={appointment.id}>
                  <div className="bg-muted px-2 py-1 rounded-md shadow-sm transition-all duration-200 hover:shadow-md hover:-translate-y-0.5 cursor-pointer flex justify-between items-center">
                    <p className="text-xs font-medium truncate flex-1">{appointment.patient}</p>
                    <p className="text-xs text-muted-foreground ml-2">{appointment.time}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex items-center space-x-2 mt-4">
          <Switch id="sync" />
          <label htmlFor="sync" className="text-xs">
            Sync with calendar
          </label>
        </div>
      </CardContent>
    </Card>
  )
} 