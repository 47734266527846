import React, { useRef, useEffect, useState } from 'react';
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from 'react-router-dom';
import CustomCanvas from "@/components/common/CustomCanvas"

const Hero = () => {

  //Navigate
  const navigate = useNavigate()
  const navToSignUp = () => {
    navigate('/signup')
  }
  const navToLogin = () => {
    navigate('/login')
  }
  return (
    <>
      {/* Hero */}
      <div className="relative overflow-hidden py-24 lg:py-32">
        {/* Gradients */}
        <div
          aria-hidden="true"
          className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
        >
          <div className="bg-gradient-to-r from-background/50 to-background blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
          <div className="bg-gradient-to-tl blur-3xl w-[90rem] h-[50rem] rounded-full origin-top-left -rotate-12 -translate-x-[15rem] from-primary-foreground via-primary-foreground to-background" />
        </div>
        {/* End Gradients */}
        <div className="relative z-10">
          <div className="container py-10 lg:py-16">
            <div className="max-w-2xl text-center mx-auto">
              <p className="text-stone-500 font-light tracking-tight">Coming soon...</p>
              {/* Title */}
              <div className="mt-5 max-w-2xl">
                <h1 className="scroll-m-20 text-4xl text-slate-700 font-extrabold tracking-tight lg:text-5xl">
                  Your Healthcare. Your hands.
                </h1>
              </div>
              {/* End Title */}
              <div className="mt-5 max-w-3xl">
                <p className="text-xl text-muted-foreground">
                  We connect customers to the best healthcare professionals.
                  Take control of your health.
                  
                </p>
              </div>
              {/* Buttons */}
              <div className="mt-8 gap-3 flex justify-center">
                <Button disabled onClick={navToSignUp}  size={"lg"}>Physician sign up</Button>
                <Button disabled onClick={navToLogin} size={"lg"} variant={"outline"}>Client sign up</Button>
              </div>
              <div className="pt-8 text-sm text-stone-800">
                <Link to="/about" className="hover:text-stone-600 transition-colors underline">Learn more about us</Link>
              </div>
              {/* End Buttons */}
            </div>
          </div>
        </div>
      </div>
      {/* End Hero */}
    </>
  );
}

const Home = () => (
  <CustomCanvas transitionSpeed={0.005}>
    <Hero/>
  </CustomCanvas>
)

export default Home;
