import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import CustomCanvas from "@/components/common/CustomCanvas";
import ProtectedBlogRoute from '@/components/auth/ProtectedBlogRoute';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';

const BlogAdminPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      // Simplified query - just get blog posts without joins
      const { data: blogPosts, error } = await supabase
        .from('blog_posts')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      // Fetch author information for each post
      const postsWithAuthors = await Promise.all(
        blogPosts.map(async (post) => {
          if (post.author_id) {
            // Get user info
            const { data: userData } = await supabase
              .from('users')
              .select('email')
              .eq('id', post.author_id)
              .single();
            
            // Get profile info if available
            const { data: profileData } = await supabase
              .from('profiles')
              .select('first_name, last_name')
              .eq('user_id', post.author_id)
              .single();
            
            return {
              ...post,
              author_email: userData?.email,
              author_name: profileData ? 
                `${profileData.first_name} ${profileData.last_name}` : 
                (userData?.email ? userData.email.split('@')[0] : 'Anonymous')
            };
          }
          return { ...post, author_name: 'Anonymous' };
        })
      );
      
      setPosts(postsWithAuthors || []);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      // If there's an error, try to fetch just the basic posts
      try {
        const { data } = await supabase
          .from('blog_posts')
          .select('*')
          .order('created_at', { ascending: false });
        
        setPosts(data || []);
      } catch (fallbackError) {
        console.error('Fallback error:', fallbackError);
      }
    } finally {
      setLoading(false);
    }
  };

  const navigateToCreate = () => {
    navigate('/blog/admin/create');
  };

  const navigateToEdit = (id) => {
    navigate(`/blog/admin/edit/${id}`);
  };

  const navigateToBlog = () => {
    navigate('/blog');
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        const { error } = await supabase
          .from('blog_posts')
          .delete()
          .eq('id', id);

        if (error) throw error;
        setPosts(posts.filter(post => post.id !== id));
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-slate-800">Blog Management</h1>
        <div>
          <Button onClick={navigateToBlog} variant="outline" className="mr-4">View Blog</Button>
          <Button onClick={navigateToCreate}>Create New Post</Button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      ) : posts.length > 0 ? (
        <div className="overflow-x-auto bg-white rounded-lg shadow">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-slate-100 border-b">
                <th className="border-b p-3 text-left">Title</th>
                <th className="border-b p-3 text-left">Subtitle</th>
                <th className="border-b p-3 text-left">Author</th>
                <th className="border-b p-3 text-left">Created</th>
                <th className="border-b p-3 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {posts.map((post) => (
                <tr key={post.id} className="border-b hover:bg-slate-50">
                  <td className="border-b p-3">{post.title}</td>
                  <td className="border-b p-3">{post.subtitle}</td>
                  <td className="border-b p-3">
                    {post.author_name || 'Anonymous'}
                  </td>
                  <td className="border-b p-3">{new Date(post.created_at).toLocaleDateString()}</td>
                  <td className="border-b p-3 text-center">
                    <Button
                      onClick={() => navigateToEdit(post.id)}
                      variant="outline"
                      className="mr-2"
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => handleDelete(post.id)}
                      variant="destructive"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center py-12 bg-white rounded-lg shadow">
          <p className="text-slate-600 mb-4">No blog posts available yet.</p>
          <Button onClick={navigateToCreate}>Create Your First Post</Button>
        </div>
      )}
    </div>
  );
};

const BlogAdmin = () => (
  <CustomCanvas>
    <ProtectedBlogRoute>
      <BlogAdminPage />
    </ProtectedBlogRoute>
  </CustomCanvas>
);

export default BlogAdmin; 