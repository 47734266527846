import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const About = () => {
  // Team members data
  const teamMembers = [
    {
      id: 1,
      name: "Ariel Braverman",
      title: "Chief Executive Officer", 
      image: "https://mateos-public-profile-pictures-us-east-1.s3.amazonaws.com/ariel.jpg",
      bio: "Ariel is a healthcare professional with more than two decades of clinical and academic experience. With a deep understanding of patient care, medical education, and healthcare systems, he leads mateos with a vision to simplify and improve access to healthcare services."
    },
    {
      id: 2,
      name: "Daniel Gamliel",
      title: "Chief Technology Officer",
      image: "https://mateos-public-profile-pictures-us-east-1.s3.amazonaws.com/daniel.jpg",
      bio: "Daniel is a senior machine learning engineer with profound technical expertise. He specializes in developing scalable AI-driven solutions and secure platforms that drive digital transformation in healthcare, ensuring innovative and efficient technology deployment at mateos."
    },
    {
      id: 3,
      name: "Naoto Oka",
      title: "Head of DevOps and Business Development",
      image: "https://mateos-public-profile-pictures-us-east-1.s3.amazonaws.com/naoto.jpg",
      bio: "Naoto ensures operational excellence and drives business development at mateos. His expertise in DevOps and strategic growth initiatives enables seamless technology deployment and expansion."
    },
    {
      id: 4,
      name: "Alina Shtainberg",
      title: "Head of Product and Marketing",
      image: "https://mateos-public-profile-pictures-us-east-1.s3.amazonaws.com/alina.jpg",
      bio: "Alina spearheads our product development and marketing strategies, blending creativity with a deep understanding of user experience and market trends to elevate the mateos brand."
    },
    {
      id: 5,
      name: "Semion Kiva",
      title: "Chief Operating Officer",
      image: "https://mateos-public-profile-pictures-us-east-1.s3.amazonaws.com/semion.png",
      bio: "Semion is a seasoned healthcare professional and entrepreneur. With extensive experience in healthcare operations and business development, he ensures sustainable growth and operational efficiency at mateos."
    }
  ];

  return (
    <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
      {/* Page Header */}
      <div className="max-w-3xl mx-auto text-center mb-16">
        <h1 className="scroll-m-20 text-5xl font-extrabold tracking-tight lg:text-6xl text-slate-700 mb-4">
          Meet Our Team
        </h1>
        <p className="text-base text-muted-foreground tracking-tight">
          The passionate individuals behind Mateos working to transform healthcare accessibility and quality.
        </p>
      </div>

      {/* Team Members Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {teamMembers.map((member) => (
          <Card key={member.id} className="overflow-hidden flex flex-col h-full transition-all duration-200 hover:shadow-lg">
            <CardHeader className="pb-0">
              <div className="flex justify-center mb-4">
                <Avatar className="h-32 w-32">
                  <AvatarImage src={member.image} alt={member.name} />
                  <AvatarFallback>{member.name.split(' ').map(n => n[0]).join('')}</AvatarFallback>
                </Avatar>
              </div>
              <CardTitle className="text-center text-xl font-bold">{member.name}</CardTitle>
              <CardDescription className="text-center text-primary font-medium">
                {member.title}
              </CardDescription>
            </CardHeader>
            <CardContent className="flex-grow">
              <p className="text-sm text-muted-foreground text-center tracking-tight">
                {member.bio}
              </p>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Mission Section */}
      <div className="max-w-3xl mx-auto mt-20 text-center">
        <h2 className="scroll-m-20 text-4xl font-bold tracking-tight mb-4 text-slate-700">
          Our Mission
        </h2>
        <p className="text-base text-muted-foreground mb-8 tracking-tight">
          At Mateos, we're committed to connecting individuals with exceptional healthcare providers. We believe everyone deserves convenient access to quality healthcare that fits their specific needs and preferences.
        </p>
        <Link to="/">
          <button className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
            Back to Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
