import { Button } from "@/components/ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useToast } from "@/components/ui/use-toast"
import { Filter } from "lucide-react"
import { useState, useEffect, useRef } from "react"
import AppointmentTable from "@/components/hcp/AppointmentTable"
import NewRequestsTable from "@/components/hcp/NewRequestTable"
import DenyModal from "@/components/hcp/DenyModal"
import { supabase } from "@/lib/supabase"

export default function AppointmentManagement() {
  const { toast } = useToast()
  const [appointments, setAppointments] = useState([])
  const [newRequests, setNewRequests] = useState([
    {
      id: 1,
      name: "Sophia Martinez", 
      avatar: "/placeholder.svg?height=32&width=32",
      date: "2023-06-20",
      time: "11:00 AM",
      careType: "Initial Consultation",
    },
    {
      id: 2,
      name: "Ethan Lee",
      avatar: "/placeholder.svg?height=32&width=32", 
      date: "2023-06-21",
      time: "2:00 PM",
      careType: "Dental Cleaning",
    },
    {
      id: 3,
      name: "Isabella Wang",
      avatar: "/placeholder.svg?height=32&width=32",
      date: "2023-06-22",
      time: "3:30 PM", 
      careType: "Follow-up",
    },
  ])

  const [isDenyModalOpen, setIsDenyModalOpen] = useState(false)
  const [denyReason, setDenyReason] = useState("")
  const [currentDenyId, setCurrentDenyId] = useState(null)
  const didFetchRef = useRef(false)

  useEffect(() => {
    if (didFetchRef.current) return
    didFetchRef.current = true

    const fetchAppointments = async () => {
      const { data: { user } } = await supabase.auth.getUser()
      if (user) {
        const { data, error } = await supabase
          .from('appointments')
          .select('*')
          .eq('provider_id', user.id)

        if (error) {
          console.error('Error fetching appointments:', error)
        } else {
          setAppointments(data || [])
        }
      }
    }

    fetchAppointments()
  }, [])

  const handleAccept = (requestId) => {
    const acceptedRequest = newRequests.find(request => request.id === requestId)
    if (acceptedRequest) {
      toast({
        title: "Request Accepted",
        description: `Appointment for ${acceptedRequest.name} on ${acceptedRequest.date} at ${acceptedRequest.time} has been accepted.`,
      })
      setNewRequests(newRequests.filter(request => request.id !== requestId))
    }
  }

  const handleDenyClick = (requestId) => {
    setCurrentDenyId(requestId)
    setIsDenyModalOpen(true)
  }

  const handleDenyConfirm = () => {
    const deniedRequest = newRequests.find(request => request.id === currentDenyId)
    if (deniedRequest) {
      toast({
        title: "Request Denied",
        description: `Appointment for ${deniedRequest.name} has been denied. Reason: ${denyReason}`,
      })
      setNewRequests(newRequests.filter(request => request.id !== currentDenyId))
    }
    setIsDenyModalOpen(false)
    setDenyReason("")
    setCurrentDenyId(null)
  }

  return (
    <div className="flex justify-center py-10">
      <div className="w-2/3">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Appointment Management</h1>
          <div className="flex gap-4">
            <Button variant="outline" className="flex items-center gap-2">
              <Filter className="h-4 w-4" />
              Filter
            </Button>
            <Button>Add Appointment</Button>
          </div>
        </div>
        <Tabs defaultValue="scheduled" className="w-full">
          <TabsList className="grid w-full grid-cols-2 mb-6">
            <TabsTrigger value="scheduled">Scheduled Appointments</TabsTrigger>
            <TabsTrigger value="requests">New Requests</TabsTrigger>
          </TabsList>
          <TabsContent value="scheduled">
            <div className="rounded-md border">
              {appointments.length > 0 ? (
                <AppointmentTable appointments={appointments} />
              ) : (
                <div className="p-4 text-center  text-gray-500">
                  No appointments yet
                </div>
              )}
            </div>
          </TabsContent>
          <TabsContent value="requests">
            <div className="rounded-md border">
              <NewRequestsTable
                requests={newRequests}
                onAccept={handleAccept}
                onDeny={handleDenyClick}
              />
            </div>
          </TabsContent>
        </Tabs>

        <DenyModal
          isOpen={isDenyModalOpen}
          onClose={() => setIsDenyModalOpen(false)}
          onConfirm={handleDenyConfirm}
          denyReason={denyReason}
          setDenyReason={setDenyReason}
        />
      </div>
    </div>
  )
}
