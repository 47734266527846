"use client"

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card"
import { ImageIcon, UserIcon } from "lucide-react"
import { useAuth } from "@/hooks/useAuth"
import { useState, useEffect } from "react"
import { supabase } from "@/lib/supabase"
import ProfilePicture from "@/components/common/ProfilePicture"
export function PersonalInfoCard() {
  const { session } = useAuth()
  const [profileData, setProfileData] = useState(null)

  useEffect(() => {
    const fetchProfile = async () => {
      if (session?.user) {
        const { data, error } = await supabase.from("profiles").select("*").eq("user_id", session.user.id).single()

        if (!error && data) {
          setProfileData(data)
        }
      }
    }

    fetchProfile()
  }, [session])

  return (
    <Card className="h-[350px] flex flex-col">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-xl font-semibold">Personal Information</CardTitle>
        <Button variant="outline" size="icon" className="h-8 w-8">
          <UserIcon className="h-4 w-4" />
        </Button>
      </CardHeader>
      <CardContent className="flex-grow py-4">
        <div className="flex flex-col sm:flex-row gap-6 items-center sm:items-start">
          <ProfilePicture 
            src="/placeholder.svg?height=128&width=128"
            alt="Profile"
            name="Jane Doe"
            className="w-32 h-32"
          />
          {/* <div className="w-32 h-32 bg-muted flex items-center justify-center rounded-md shrink-0">
            <ImageIcon className="h-16 w-16 text-muted-foreground" />
          </div> */}
          <div className="space-y-2 text-left">
            <p className="text-lg font-medium">
              {profileData ? `Dr. ${profileData.first_name} ${profileData.last_name}` : "Loading..."}
            </p>
            <p className="text-sm text-muted-foreground">
              <span className="font-medium">License:</span> {profileData?.license_type || "Not set"}
            </p>
            <p className="text-sm text-muted-foreground">
              <span className="font-medium">Specialization:</span> {profileData?.specialization || "Not set"}
            </p>
            <p className="text-sm text-muted-foreground">
              <span className="font-medium">Sub-specialization:</span> {profileData?.sub_specialization || "Not set"}
            </p>
          </div>
        </div>
      </CardContent>
      <CardFooter className="pt-2">
        <Button className="w-full" variant="default">
          Generate my digital ID
        </Button>
      </CardFooter>
    </Card>
  )
}

