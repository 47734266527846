// export default DoctorSidebar;
import * as React from "react"
import { Link } from "react-router-dom"

import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from "@/components/ui/sidebar"
import { GalleryVerticalEnd } from "lucide-react"


const data = {
  versions: ["1.0.0"],
  navMain: [
    {
      title: "Main",
      component: "",
      items: [
        {
          title: "Appointments",
          component: "appointments",
        },
        {
          title: "Calendar",
          component: "calendar",
        },
        {
          title: "Services",
          component: "services",
        },
      ],
    },
    {
      title: "Finance",
      component: "",
      items: [
        {
          title: "Transactions",
          component: "transactions",
        },
        {
          title: "Billing",
          component: "billing",
        },
      ],
    },
    {
      title: "Account",
      component: "",
      items: [
        {
          title: "Profile",
          component: "profile",
        },
      ],
    },
  ],
}

export default function DoctorSidebar({ onSelect, ...props }) {
  return (
    <Sidebar {...props}>
      <SidebarHeader>
        <SidebarMenuButton size="lg" asChild>
          <Link to="/dashboard" onClick={() => onSelect('')}>
            <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
              <GalleryVerticalEnd className="size-4" />
            </div>
            <div className="flex flex-col gap-0.5 leading-none">
              <span className="font-semibold">Mateos</span>
              <span className="">Home</span>
            </div>
          </Link>
        </SidebarMenuButton>
      </SidebarHeader>
      <SidebarContent>
        {/* We create a SidebarGroup for each parent. */}
        {data.navMain.map((item) => (
          <SidebarGroup key={item.title}>
            <SidebarGroupLabel>{item.title}</SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {item.items.map((item) => (
                  <SidebarMenuItem key={item.title}> 
                    <SidebarMenuButton 
                      asChild 
                      isActive={item.isActive} 
                      onClick={() => onSelect(item.component)}
                      className="hover:bg-white hover:translate-y-[-2px] transition-all duration-200 cursor-pointer"
                    >
                      <span>{item.title}</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        ))}
      </SidebarContent>
      <SidebarRail />
    </Sidebar>
  )
}
