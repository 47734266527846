import { useState } from 'react'
import { Card, CardContent } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Loader2, X } from "lucide-react"
import License from "@/components/auth/License"
import ProfilePicture from "@/components/common/ProfilePicture"

const GoogleIcon = ({className}) => {
  return (
    <svg className={className ? className : ""} version="1.1" viewBox="0 0 48 48" style={{ display: "block" }}>
      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
      <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
      <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
      <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
      <path fill="none" d="M0 0h48v48H0z"></path>
    </svg>
  );
};

const CardSignIn = ({isVisible}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setIsOpen] = useState(true)
  const [showLicense, setShowLicense] = useState(false)

  const handleGoogleSignIn = () => {
    setShowLicense(true)
  }

  const handleLicenseAgree = () => {
    setIsLoading(true)
    // Your sign-in logic here
    // For example: Auth.federatedSignIn({ provider: 'Google' })
    setIsLoading(false)
    setIsOpen(false) // Close the card when license is accepted
  }

  return (
    isVisible && open && (
      <>
        <License 
          isOpen={showLicense} 
          onClose={() => setShowLicense(false)}
          onAgree={handleLicenseAgree}
        />
        <div className="fixed top-20 right-20 z-50">
          <Card className="w-[300px] shadow-lg">
            <CardContent className="p-4">
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center">
                  <GoogleIcon className="mr-2 h-8 w-8"/>
                  <span className="text-sm text-gray-600">Sign with Google</span>
                </div>
                <Button onClick={() => setIsOpen(false)} variant="ghost" size="icon" className="h-6 w-6">
                  <X className="h-4 w-4" />
                </Button>
              </div>
              <div className="flex items-center gap-2 mb-4">
                {/* <img src="/placeholder.svg?height=40&width=40" alt="User avatar" className="w-10 h-10 rounded-full mr-3" /> */}
                <ProfilePicture
                  src="path/to/some/file.jpg"
                  name="Daniel Gamliel"
                />
                <div>
                  <p className="font-semibold">Daniel Gamliel</p>
                  <p className="text-xs text-gray-500">dgamliel7@gmail.com</p>
                </div>
              </div>
              <Button 
                className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                onClick={handleGoogleSignIn}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  'Continue as Daniel'
                )}
              </Button>
            </CardContent>
          </Card>
        </div>
      </>
    )
  )
}

export default CardSignIn
