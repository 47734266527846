import { useState, useEffect } from 'react'
import { supabase } from '@/lib/supabase'
import { storeAuthData, getStoredAuthData, clearAuthData } from '@/lib/authStore'

export const useAuth = () => {
  const [session, setSession] = useState(getStoredAuthData().session)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Initial session check
    const initSession = async () => {
      try {
        const { data: { session: supabaseSession } } = await supabase.auth.getSession()
        
        if (supabaseSession) {
          // Fetch provider profile if it exists
          const { data: providerData } = await supabase
            .from('providers_profile')
            .select('id')
            .eq('user_id', supabaseSession.user.id)
            .single()

          // Store all auth data
          storeAuthData({
            userId: supabaseSession.user.id,
            providerId: providerData?.id,
            session: supabaseSession
          })

          setSession(supabaseSession)
        } else {
          clearAuthData()
          setSession(null)
        }
      } catch (error) {
        console.error('Error initializing session:', error)
        clearAuthData()
        setSession(null)
      } finally {
        setLoading(false)
      }
    }

    initSession()

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_event, newSession) => {
      if (newSession) {
        // Fetch provider profile if it exists
        const { data: providerData } = await supabase
          .from('providers_profile')
          .select('id')
          .eq('user_id', newSession.user.id)
          .single()

        // Store all auth data
        storeAuthData({
          userId: newSession.user.id,
          providerId: providerData?.id,
          session: newSession
        })
      } else {
        clearAuthData()
      }
      setSession(newSession)
    })

    return () => subscription.unsubscribe()
  }, [])

  return { session, loading }
} 