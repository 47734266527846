import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';
import { getStoredAuthData, storeAuthData } from '@/lib/authStore';

const ProtectedRoute = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateUser = async () => {
      try {
        // First check for active session
        const { data: { session } } = await supabase.auth.getSession();
        console.log('session', session?.user?.id);
        if (!session?.user) {
          throw new Error('No authenticated user');
        }

        // Get provider profile with full details
        const { data: providerData, error: providerError } = await supabase
          .from('providers_profile')
          .select('id, user_id, first_name, last_name, email')
          .eq('user_id', session.user.id)
          .single();

        console.log('providerData', providerData)

        if (providerError || !providerData) {
          throw new Error('No provider profile found');
        }

        // Store complete auth data
        storeAuthData({ 
          userId: session.user.id,
          providerId: providerData.id,
          session: session,
          profileData: {
            firstName: providerData.first_name,
            lastName: providerData.last_name,
            email: providerData.email
          }
        });

        setIsAuthorized(true);
      } catch (error) {
        console.error('Authorization error:', error);
        setIsAuthorized(false);
      } finally {
        setLoading(false);
      }
    };

    validateUser();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!isAuthorized) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;