import { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Skeleton } from "@/components/ui/skeleton"
import { UserIcon as GenderMale, Stethoscope, Calendar, Phone, Upload, PencilIcon } from "lucide-react"
import { supabase } from "@/lib/supabase"
import { useAuth } from "@/hooks/useAuth"
import { storeAuthData } from "@/lib/authStore"

//TODO: Fix bug where we need to refresh the page to see the updated profile
export default function PersonalInformationCard() {
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { session } = useAuth()
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    licenseType: "",
    licenseYear: "",
    phoneNumber: "",
    photo: null,
  })

  useEffect(() => {
    fetchProviderData()
  }, [])

  const fetchProviderData = async () => {
    setIsLoading(true)
    const {
      data: { user },
    } = await supabase.auth.getUser()
    if (user) {
      const { data: providerData, error } = await supabase
        .from("profiles")
        .select("first_name, last_name, license_number")
        .eq("user_id", user.id)
        .single()

      if (!error && providerData) {
        setFormData((prevState) => ({
          ...prevState,
          firstName: providerData.first_name || "",
          lastName: providerData.last_name || "",
          licenseType: providerData.license_number || "",
          gender: providerData.gender || "",
          licenseYear: providerData.license_year || "",
          phoneNumber: providerData.phone_number || "",
        }))
      }
    }
    setIsLoading(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handlePhotoUpload = (e) => {
    const file = e.target.files?.[0]
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        photo: URL.createObjectURL(file),
      }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const {
      data: { user },
    } = await supabase.auth.getUser()
    if (user) {
      const { data: updatedProfile, error } = await supabase
        .from("profiles")
        .update({
          first_name: formData.firstName,
          last_name: formData.lastName,
          license_number: formData.licenseType,
          // gender: formData.gender,
          // license_year: formData.licenseYear,
          // phone_number: formData.phoneNumber,
        })
        .eq("user_id", user.id)
        .select()
        .single()

      if (error) {
        console.error("Error updating profile:", error)
      } else {
        // Update local storage with new profile data
        storeAuthData({
          userId: user.id,
          providerId: updatedProfile.id,
          session: session,
          profileData: updatedProfile
        })
        setIsEditing(false)
      }
    }
  }

  const SkeletonField = () => (
    <div className="space-y-2">
      <Skeleton className="h-4 w-[100px]" />
      <Skeleton className="h-8 w-full" />
    </div>
  )

  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen flex items-center justify-center p-4">
      <Card className="w-full max-w-4xl shadow-lg">
        <CardHeader className="flex flex-row items-center justify-between bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-t-lg">
          <CardTitle className="text-3xl font-bold">Personal Information</CardTitle>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsEditing(!isEditing)}
            className="text-white hover:bg-white/20"
          >
            <PencilIcon className="h-5 w-5" />
          </Button>
        </CardHeader>
        <CardContent className="p-6">
          <AnimatePresence mode="wait">
            {isLoading ? (
              <motion.div
                key="loading"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="space-y-6"
              >
                <div className="grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8">
                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <SkeletonField />
                      <SkeletonField />
                    </div>
                    <SkeletonField />
                    <SkeletonField />
                    <SkeletonField />
                    <SkeletonField />
                  </div>
                  <div>
                    <Skeleton className="h-4 w-[100px] mb-2" />
                    <Skeleton className="h-64 w-full" />
                  </div>
                </div>
              </motion.div>
            ) : isEditing ? (
              <motion.form
                key="edit"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                onSubmit={handleSubmit}
                className="space-y-8"
              >
                <div className="grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8">
                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <Label htmlFor="firstName" className="text-slate-800">
                          First Name
                        </Label>
                        <Input
                          id="firstName"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          className="mt-1"
                        />
                      </div>
                      <div>
                        <Label htmlFor="lastName" className="text-slate-800">
                          Last Name
                        </Label>
                        <Input
                          id="lastName"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          className="mt-1"
                        />
                      </div>
                    </div>

                    <div>
                      <Label htmlFor="gender" className="flex items-center gap-2 text-slate-800">
                        <GenderMale className="w-4 h-4" />
                        Gender (Optional)
                      </Label>
                      <Input
                        id="gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleInputChange}
                        className="mt-1"
                      />
                    </div>

                    <div>
                      <Label htmlFor="licenseType" className="flex items-center gap-2 text-slate-800">
                        <Stethoscope className="w-4 h-4" />
                        License Number
                      </Label>
                      <Input
                        id="licenseType"
                        name="licenseType"
                        value={formData.licenseType}
                        onChange={handleInputChange}
                        className="mt-1"
                      />
                    </div>

                    <div>
                      <Label htmlFor="licenseYear" className="flex items-center gap-2 text-slate-800">
                        <Calendar className="w-4 h-4" />
                        License year
                      </Label>
                      <Input
                        id="licenseYear"
                        name="licenseYear"
                        value={formData.licenseYear}
                        onChange={handleInputChange}
                        className="mt-1"
                      />
                    </div>

                    <div>
                      <Label htmlFor="phoneNumber" className="flex items-center gap-2 text-slate-800">
                        <Phone className="w-4 h-4" />
                        Phone number
                      </Label>
                      <Input
                        id="phoneNumber"
                        name="phoneNumber"
                        type="tel"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        className="mt-1"
                      />
                    </div>
                  </div>

                  <div className="space-y-4">
                    <Label className="flex items-center gap-2 text-slate-800">
                      <Upload className="w-4 h-4" />
                      Photo
                    </Label>
                    <div className="relative aspect-square rounded-lg border-2 border-dashed border-muted-foreground/25 hover:border-muted-foreground/50 transition-colors">
                      {formData.photo ? (
                        <img
                          src={formData.photo || "/placeholder.svg"}
                          alt="Profile preview"
                          className="w-full h-full object-cover rounded-lg"
                        />
                      ) : (
                        <div className="absolute inset-0 flex items-center justify-center text-muted-foreground">
                          Upload a photo
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handlePhotoUpload}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end pt-4 space-x-4">
                  <Button type="submit" variant="default" size="lg" className="bg-blue-600 hover:bg-blue-700">
                    Save
                  </Button>
                  <Button type="button" variant="outline" size="lg" onClick={() => setIsEditing(false)}>
                    Cancel
                  </Button>
                </div>
              </motion.form>
            ) : (
              <motion.div
                key="view"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8"
              >
                <div className="space-y-6">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <Label className="text-slate-600">First Name</Label>
                      <p className="mt-1 text-lg font-medium">{formData.firstName}</p>
                    </div>
                    <div>
                      <Label className="text-slate-600">Last Name</Label>
                      <p className="mt-1 text-lg font-medium">{formData.lastName}</p>
                    </div>
                  </div>

                  <div>
                    <Label className="flex items-center gap-2 text-slate-600">
                      <GenderMale className="w-4 h-4" />
                      Gender
                    </Label>
                    <p className="mt-1 text-lg font-medium">{formData.gender || "Not specified"}</p>
                  </div>

                  <div>
                    <Label className="flex items-center gap-2 text-slate-600">
                      <Stethoscope className="w-4 h-4" />
                      License Number
                    </Label>
                    <p className="mt-1 text-lg font-medium">{formData.licenseType}</p>
                  </div>

                  <div>
                    <Label className="flex items-center gap-2 text-slate-600">
                      <Calendar className="w-4 h-4" />
                      License Year
                    </Label>
                    <p className="mt-1 text-lg font-medium">{formData.licenseYear}</p>
                  </div>

                  <div>
                    <Label className="flex items-center gap-2 text-slate-600">
                      <Phone className="w-4 h-4" />
                      Phone Number
                    </Label>
                    <p className="mt-1 text-lg font-medium">{formData.phoneNumber}</p>
                  </div>
                </div>

                <div>
                  <Label className="mb-2 text-slate-600">Photo</Label>
                  <div className="aspect-square rounded-lg border-2 border-muted overflow-hidden">
                    {formData.photo ? (
                      <img
                        src={formData.photo || "/placeholder.svg"}
                        alt="Profile"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center text-muted-foreground bg-slate-100">
                        No photo uploaded
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </CardContent>
      </Card>
    </div>
  )
}