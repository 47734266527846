import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { BarChart3Icon } from "lucide-react"
import { useState, useEffect } from "react"
import { supabase } from "@/lib/supabase"
import { getStoredAuthData } from "@/lib/authStore"

export function StatisticsCard() {
  const [stats, setStats] = useState({
    appointmentCount: 0,
    totalIncome: 0,
    frequentService: ''
  })

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const { providerId } = getStoredAuthData()
        if (!providerId) return

        // Get last month's appointments
        const thirtyDaysAgo = new Date()
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

        const { data: appointments, error: appointmentsError } = await supabase
          .from('appointments')
          .select('*, services(price, name)')
          .eq('provider_id', providerId)
          .gte('appointment_date', thirtyDaysAgo.toISOString())

        if (appointmentsError) throw appointmentsError

        // Calculate stats
        const appointmentCount = appointments?.length || 0
        const totalIncome = appointments?.reduce((sum, apt) => sum + (apt.services?.price || 0), 0) || 0

        // Find most frequent service
        const serviceCount = {}
        appointments?.forEach(apt => {
          const serviceName = apt.services?.name
          if (serviceName) {
            serviceCount[serviceName] = (serviceCount[serviceName] || 0) + 1
          }
        })

        const frequentService = Object.entries(serviceCount)
          .sort(([,a], [,b]) => b - a)[0]?.[0] || 'None'

        setStats({
          appointmentCount,
          totalIncome,
          frequentService
        })

      } catch (error) {
        console.error('Error fetching statistics:', error)
      }
    }

    fetchStats()
  }, [])

  return (
    <Card className="h-[350px] relative">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle>Statistics</CardTitle>
        <Button variant="outline" size="icon" className="h-8 w-8">
          <BarChart3Icon className="h-4 w-4" />
        </Button>
      </CardHeader>
      <CardContent>
        <div className="space-y-4 mt-4">
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">Last 30 days appointments</p>
            <p className="text-2xl font-semibold">{stats.appointmentCount}</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">Total income</p>
            <p className="text-2xl font-semibold">${stats.totalIncome.toFixed(2)}</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">Most frequent service</p>
            <p className="text-2xl font-semibold">{stats.frequentService || 'N/A'}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}