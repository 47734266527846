import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import SearchContainer from "@/components/client/SearchContainer";
import NavBar from "@/components/layout/NavBar";

const SearchResults = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  const searchResults = useMemo(() => {
    try {
      return JSON.parse(searchParams.get('results') || '[]');
    } catch (e) {
      console.error('Error parsing search results:', e);
      return [];
    }
  }, [location.search]);

  const searchCriteria = useMemo(() => ({
    searchTerm: searchParams.get('searchTerm') || '',
    date: searchParams.get('date') ? new Date(searchParams.get('date')) : null,
    place: searchParams.get('place') || '92627',
    minPrice: searchParams.get('minPrice') || '',
    maxPrice: searchParams.get('maxPrice') || '',
    selectedOption: searchParams.get('selectedOption') || 'Choose Selection',
    results: searchResults
  }), [location.search, searchResults]);

  return (
    <>
      <NavBar/>
      <SearchContainer initialSearchParams={searchCriteria} />
    </>
  );
};

export default SearchResults;
