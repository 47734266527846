import { createClient } from '@supabase/supabase-js'

if (!process.env.REACT_APP_SUPABASE_URL || !process.env.REACT_APP_SUPABASE_ANON_KEY) {
    throw new Error('Missing environment variables: REACT_APP_SUPABASE_URL and REACT_APP_SUPABASE_ANON_KEY must be defined')
}

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY

// console.log(`Supbase URL ${supabaseUrl}`)
// console.log(`Supbase Key ${supabaseKey}`)

export const supabase = createClient(supabaseUrl, supabaseKey)