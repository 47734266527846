// Constants for storage keys
const STORAGE_KEYS = {
  USER_ID: 'mateo_user_id',
  PROVIDER_ID: 'mateo_provider_id',
  PROFILE_ID: 'mateo_profile_id',
  SESSION: 'mateo_session',
  PROFILE_DATA: 'mateo_profile_data'
}

// Store auth data
export const storeAuthData = ({userId, providerId, profileId, session, profileData}) => {
  if (userId) localStorage.setItem(STORAGE_KEYS.USER_ID, userId)
  if (providerId) localStorage.setItem(STORAGE_KEYS.PROVIDER_ID, providerId)
  if (profileId) localStorage.setItem(STORAGE_KEYS.PROFILE_ID, profileId)
  if (session) localStorage.setItem(STORAGE_KEYS.SESSION, JSON.stringify(session))
  if (profileData) localStorage.setItem(STORAGE_KEYS.PROFILE_DATA, JSON.stringify(profileData))
}

// Get stored auth data
export const getStoredAuthData = () => ({
  userId: localStorage.getItem(STORAGE_KEYS.USER_ID),
  providerId: localStorage.getItem(STORAGE_KEYS.PROVIDER_ID),
  profileId: localStorage.getItem(STORAGE_KEYS.PROFILE_ID),
  session: JSON.parse(localStorage.getItem(STORAGE_KEYS.SESSION) || 'null'),
  profileData: JSON.parse(localStorage.getItem(STORAGE_KEYS.PROFILE_DATA) || 'null')
})

// Clear auth data
export const clearAuthData = () => {
  localStorage.removeItem(STORAGE_KEYS.USER_ID)
  localStorage.removeItem(STORAGE_KEYS.PROVIDER_ID)
  localStorage.removeItem(STORAGE_KEYS.PROFILE_ID)
  localStorage.removeItem(STORAGE_KEYS.SESSION)
  localStorage.removeItem(STORAGE_KEYS.PROFILE_DATA)
} 