import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '@/lib/supabase';

//Components
import SearchForm from "@/components/client/SearchForm";
import NavBar from "@/components/layout/NavBar";
import CardSignIn from "@/components/auth/SignInCard";

const Hero = ({ isVisible, user }) => (
  <div className={`
    text-center mb-8
    transition-all duration-300 ease-in-out
    ${isVisible ? 'max-h-96 opacity-100 mb-8' : 'max-h-0 opacity-0 mb-0'}
    overflow-hidden
  `}>
    {user ? (
      <>
        <h1 className="text-4xl text-slate-700 font-bold mb-2">
          Hello, {user.user_metadata.first_name || user.email}
        </h1>
        <p className="text-lg text-gray-500">Find the healthcare services you need, all in one place.</p>
      </>
    ) : (
      <>
        <h1 className="text-4xl text-slate-700 font-bold mb-2">Find Your Healthcare Professional</h1>
        <p className="text-lg text-gray-500">Search for doctors, specialists, and healthcare services near you.</p>
      </>
    )}
  </div>
);

const SearchPage = () => {
  const [user, setUser] = useState(null);
  const [providers, setProviders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        // Get providers with their profiles
        const { data, error } = await supabase
          .from('providers')
          .select(`
            id,
            profile_id,
            license,
            specialization,
            is_active,
            profiles (
              id, 
              first_name,
              last_name,
              profile_type,
              user_id
            ),
            services!services_provider_id_fkey (
              id,
              name,
              description,
              price,
              duration
            )
          `)
          .eq('is_active', true);
        
        if (error) {
          console.error('Error fetching providers:', error);
          return;
        }
        
        // Process providers to ensure each has a name property
        const processedProviders = (data || []).map(provider => {
          const profile = provider.profiles || {};
          return {
            ...provider,
            name: profile.first_name && profile.last_name 
              ? `${profile.first_name} ${profile.last_name}`
              : 'Unknown Provider'
          };
        });
        
        setProviders(processedProviders);
      } catch (error) {
        console.error('Error in fetchProviders:', error);
      }
    };

    fetchProviders();
  }, []);

  const handleSearch = async (searchParams) => {
    try {
      if (!searchParams.searchTerm.trim()) {
        const queryString = new URLSearchParams({
          ...searchParams,
          results: JSON.stringify(providers)
        }).toString();
        
        navigate(`/results?${queryString}`);
        return;
      }

      // Search for services matching the search term
      const { data: servicesResults, error: servicesError } = await supabase
        .from('services')
        .select(`
          id,
          name,
          description,
          price,
          duration,
          provider_id
        `)
        .ilike('name', `%${searchParams.searchTerm}%`);
        
      if (servicesError) {
        console.error('Error searching services:', servicesError);
        return;
      }

      // Collect all provider IDs from the services
      const providerIds = servicesResults.map(service => service.provider_id).filter(Boolean);
      
      // If we have provider IDs, fetch the provider data
      let providerData = [];
      if (providerIds.length > 0) {
        const { data: providerResults, error: providerError } = await supabase
          .from('providers')
          .select(`
            id,
            profile_id,
            license,
            specialization,
            is_active,
            addresses!addresses_provider_id_fkey (
              id,
              street,
              city,
              state,
              zip_code,
              is_primary
            ),
            profiles (
              first_name,
              last_name,
              profile_type
            )
          `)
          .in('id', providerIds)
          .eq('is_active', true);
          
        if (providerError) {
          console.error('Error fetching providers:', providerError);
        } else {
          providerData = providerResults || [];
        }
      }
      
      // Combine service and provider data
      let uniqueProviders = [];
      providerData.forEach(provider => {
        const matchingServices = servicesResults.filter(s => s.provider_id === provider.id);
        
        if (matchingServices.length > 0) {
          // Find the primary address
          const primaryAddress = provider.addresses?.find(addr => addr.is_primary) || provider.addresses?.[0];
          
          // Get the best matching service
          const bestMatchService = matchingServices.reduce((best, current) => {
            const bestScore = calculateMatchScore(best.name, searchParams.searchTerm);
            const currentScore = calculateMatchScore(current.name, searchParams.searchTerm);
            return currentScore > bestScore ? current : best;
          }, matchingServices[0]);
          
          const profile = provider.profiles || {};
          
          uniqueProviders.push({
            id: provider.id,
            profile_id: provider.profile_id,
            name: profile.first_name && profile.last_name 
                  ? `${profile.first_name} ${profile.last_name}` 
                  : 'Unknown Provider',
            specialization: provider.specialization,
            city: primaryAddress?.city,
            state: primaryAddress?.state,
            street: primaryAddress?.street,
            license: provider.license,
            profile_type: profile.profile_type,
            is_active: provider.is_active,
            matchScore: calculateMatchScore(bestMatchService.name, searchParams.searchTerm),
            matchedService: bestMatchService.name,
            price: bestMatchService.price
          });
        }
      });

      // If we have few results, do a fuzzy search
      if (uniqueProviders.length < 5) {
        const searchWords = searchParams.searchTerm.toLowerCase().split(/\s+/);
        
        for (const word of searchWords) {
          if (word.length < 3) continue;
          
          // Fuzzy search on services
          const { data: fuzzyResults, error: fuzzyError } = await supabase
            .from('services')
            .select(`
              id,
              name,
              description,
              price,
              duration,
              provider_id
            `)
            .or(`name.ilike.%${word}%,description.ilike.%${word}%`);
            
          if (fuzzyError) {
            console.error('Error in fuzzy search:', fuzzyError);
            continue;
          }
          
          // Find provider IDs not already in our list
          const existingProviderIds = uniqueProviders.map(p => p.id);
          const fuzzyProviderIds = fuzzyResults
            .map(service => service.provider_id)
            .filter(id => !existingProviderIds.includes(id)); 
            
          if (fuzzyProviderIds.length > 0) {
            const { data: additionalProviders, error: additionalError } = await supabase
              .from('providers')
              .select(`
                id,
                profile_id,
                license,
                specialization,
                is_active,
                addresses!addresses_provider_id_fkey (
                  id,
                  street,
                  city,
                  state,
                  zip_code,
                  is_primary
                ),
                profiles (
                  first_name,
                  last_name,
                  profile_type
                )
              `)
              .in('id', fuzzyProviderIds)
              .eq('is_active', true);
              
            if (additionalError) {
              console.error('Error fetching additional providers:', additionalError);
              continue;
            }
            
            // Process additional providers
            additionalProviders?.forEach(provider => {
              const matchingServices = fuzzyResults.filter(s => s.provider_id === provider.id);
              
              if (matchingServices.length > 0) {
                const primaryAddress = provider.addresses?.find(addr => addr.is_primary) || provider.addresses?.[0];
                const bestMatchService = matchingServices.reduce((best, current) => {
                  const bestScore = calculateMatchScore(best.name, word);
                  const currentScore = calculateMatchScore(current.name, word);
                  return currentScore > bestScore ? current : best;
                }, matchingServices[0]);
                
                const profile = provider.profiles || {};
                
                uniqueProviders.push({
                  id: provider.id,
                  profile_id: provider.profile_id,
                  name: profile.first_name && profile.last_name 
                        ? `${profile.first_name} ${profile.last_name}` 
                        : 'Unknown Provider',
                  specialization: provider.specialization,
                  city: primaryAddress?.city,
                  state: primaryAddress?.state,
                  street: primaryAddress?.street,
                  license: provider.license,
                  profile_type: profile.profile_type,
                  is_active: provider.is_active,
                  matchScore: calculateMatchScore(bestMatchService.name, word) * 0.8, // Lower score for fuzzy matches
                  matchedService: bestMatchService.name,
                  price: bestMatchService.price
                });
              }
            });
          }
        }
      }

      // Sort by match score
      uniqueProviders.sort((a, b) => b.matchScore - a.matchScore);

      const queryString = new URLSearchParams({
        ...searchParams,
        results: JSON.stringify(uniqueProviders)
      }).toString();
      
      navigate(`/results?${queryString}`);
    } catch (error) {
      console.error('Error in handleSearch:', error);
    }
  };

  const calculateMatchScore = (serviceName, searchTerm) => {
    const serviceNameLower = serviceName.toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    
    if (serviceNameLower === searchTermLower) {
      return 1;
    }
    
    if (serviceNameLower.startsWith(searchTermLower)) {
      return 0.9;
    }
    
    if (serviceNameLower.includes(searchTermLower)) {
      return 0.8;
    }
    
    const serviceWords = serviceNameLower.split(/\s+/);
    const searchWords = searchTermLower.split(/\s+/);
    
    let matchCount = 0;
    for (const searchWord of searchWords) {
      for (const serviceWord of serviceWords) {
        if (serviceWord.includes(searchWord) || searchWord.includes(serviceWord)) {
          matchCount++;
          break;
        }
      }
    }
    
    return matchCount / searchWords.length * 0.7;
  };

  return (
    <>
      <NavBar />
      <div className="container mx-auto px-4 py-8">
        <Hero isVisible={true} user={user} />
        <div className="flex justify-center w-full">
          <SearchForm onSearch={handleSearch} />
        </div>
      </div>
    </>
  );
};

export default SearchPage;
