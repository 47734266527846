import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import CustomCanvas from "@/components/common/CustomCanvas";
import ProtectedBlogRoute from '@/components/auth/ProtectedBlogRoute';

const BlogFormPage = () => {
  const { id } = useParams();
  const isEditMode = !!id;
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    content: '',
  });
  const [loading, setLoading] = useState(isEditMode);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isEditMode) {
      fetchPost();
    }
  }, [id]);

  const fetchPost = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      
      setFormData({
        title: data.title || '',
        subtitle: data.subtitle || '',
        content: data.content || '',
      });
    } catch (error) {
      console.error('Error fetching blog post:', error);
      setError('Error loading blog post. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.title.trim()) {
      setError('Title is required');
      return;
    }

    try {
      setSaving(true);
      setError(null);

      if (isEditMode) {
        // Update existing post
        const { error } = await supabase
          .from('blog_posts')
          .update({
            title: formData.title,
            subtitle: formData.subtitle,
            content: formData.content,
            updated_at: new Date().toISOString(),
          })
          .eq('id', id);

        if (error) throw error;
        navigate('/blog/admin');
      } else {
        // Create new post
        const { data: { session } } = await supabase.auth.getSession();
        
        const { error } = await supabase
          .from('blog_posts')
          .insert([
            {
              title: formData.title,
              subtitle: formData.subtitle,
              content: formData.content,
              author_id: session?.user?.id || null,
            }
          ]);

        if (error) throw error;
        navigate('/blog/admin');
      }
    } catch (error) {
      console.error('Error saving blog post:', error);
      setError('Error saving blog post. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const navigateBack = () => {
    navigate('/blog/admin');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12 max-w-3xl">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-slate-800">
          {isEditMode ? 'Edit Blog Post' : 'Create New Blog Post'}
        </h1>
        <Button onClick={navigateBack} variant="outline">
          Cancel
        </Button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="title">Title *</Label>
          <Input
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Enter post title"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="subtitle">Subtitle</Label>
          <Input
            id="subtitle"
            name="subtitle"
            value={formData.subtitle}
            onChange={handleChange}
            placeholder="Enter post subtitle"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="content">Content</Label>
          <Textarea
            id="content"
            name="content"
            value={formData.content}
            onChange={handleChange}
            placeholder="Write your blog post content..."
            className="min-h-[400px]"
          />
        </div>

        <div className="flex justify-end pt-4">
          <Button type="submit" disabled={saving}>
            {saving ? (
              <>
                <span className="animate-spin mr-2">⏳</span>
                {isEditMode ? 'Updating...' : 'Creating...'}
              </>
            ) : (
              isEditMode ? 'Update Post' : 'Create Post'
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

const BlogForm = () => (
  <CustomCanvas>
    <ProtectedBlogRoute>
      <BlogFormPage />
    </ProtectedBlogRoute>
  </CustomCanvas>
);

export default BlogForm; 