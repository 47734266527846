import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button"
import { Card, CardFooter } from "@/components/ui/card"
import AccountInfo from '@/components/auth/WizardAccountInfo'
import ProfessionalInfo from '@/components/auth/WizardProfessionalInfo'
import ServicesAndPricing from '@/components/auth/WizardServices'
import ReviewCard from '@/components/auth/WizardReview'
import {supabase} from "@/lib/supabase"

export default function SignUpWizard() {

  const navigate = useNavigate();
  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    email: '',
    license: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    services: []
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleServiceChange = (index, e) => {
    const { name, value } = e.target
    const updatedServices = [...formData.services]
    updatedServices[index] = { ...updatedServices[index], [name]: value }
    setFormData(prevData => ({
      ...prevData,
      services: updatedServices
    }))
  }

  const addService = () => {
    setFormData(prevData => ({
      ...prevData,
      services: [...prevData.services, { name: '', price: '' }]
    }))
  }

  const validateStep = () => {
    switch(step) {
      case 1:
        return formData.firstName && formData.lastName && formData.email && formData.password;
      case 2:
        return formData.license && formData.street && formData.city && formData.state && formData.zipCode;
      case 3:
        return formData.services.length > 0 && formData.services.every(service => service.name && service.price);
      case 4:
        return true;
      default:
        return false;
    }
  }

  const nextStep = () => {
    if (step < 4 && validateStep()) setStep(step + 1)
  }
  
  const prevStep = () => {
    if (step > 1) setStep(step - 1)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            user_type: 'provider',
            first_name: formData.firstName,
            last_name: formData.lastName,
            license: formData.license,
            specialization: formData.specialization,
            services: formData.services,
            street: formData.street,
            city: formData.city, 
            state: formData.state,
            zip_code: formData.zipCode
          }
        }
      });

      if (error) throw error;

      const { data: providerData, error: providerError } = await supabase
      .rpc('create_provider_profile', {
        auth_id: data.user.id,
        user_metadata: data.user.user_metadata
      });
  
      if (error) throw error;

      else{
        navigate('/dashboard');
      }
  
    } catch (error) {
      console.error(error);
      // Add error handling UI feedback here
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (step === 4) {
        handleSubmit(e);
      } else if (validateStep()) {
        nextStep();
      }
    }
  }

  const renderStep = () => {
    switch(step) {
      case 1:
        return <AccountInfo formData={formData} handleChange={handleChange} />
      case 2:
        return <ProfessionalInfo formData={formData} handleChange={handleChange} />
      case 3:
        return <ServicesAndPricing formData={formData} handleServiceChange={handleServiceChange} addService={addService} />
      case 4:
        return <ReviewCard formData={formData} />
      default:
        return <div>Error</div>
    }
  }

  const NavigationButton = ({ step, onNext, onSubmit }) => {
    const isStepValid = validateStep();
    
    const getButtonText = () => {
      switch(step) {
        case 3:
          return "Review";
        case 4:
          return "Submit";
        default:
          return "Next";
      }
    };

    return (
      <Button 
        onClick={step === 4 ? onSubmit : onNext}
        type={step === 4 ? "submit" : "button"}
        disabled={!isStepValid}
      >
        {getButtonText()}
      </Button>
    );
  };

  const size = step >= 3 ? 'max-w-3xl' : 'max-w-md';

  return (
    <div className="flex items-center justify-center min-h-screen p-4">
      <form onSubmit={handleSubmit} onKeyPress={handleKeyPress} className={`w-full ${size}`}>
        <Card className="w-full">
          {renderStep()}
          <CardFooter className="flex justify-between">
            {step > 1 && (
              <Button type="button" onClick={prevStep} variant="outline">
                Previous
              </Button>
            )}
            <NavigationButton 
              step={step}
              onNext={nextStep}
              onSubmit={handleSubmit}
            />
          </CardFooter>
        </Card>
      </form>
    </div>
  )
}
