import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import CustomCanvas from "@/components/common/CustomCanvas";

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Simplified query - just get blog posts without joins
        const { data: blogPosts, error } = await supabase
          .from('blog_posts')
          .select('*')
          .order('created_at', { ascending: false });

        if (error) throw error;
        
        // Fetch author information for each post
        const postsWithAuthors = await Promise.all(
          blogPosts.map(async (post) => {
            if (post.author_id) {
              // Get user info
              const { data: userData } = await supabase
                .from('users')
                .select('email')
                .eq('id', post.author_id)
                .single();
              
              // Get profile info if available
              const { data: profileData } = await supabase
                .from('profiles')
                .select('first_name, last_name')
                .eq('user_id', post.author_id)
                .single();
              
              return {
                ...post,
                author_email: userData?.email,
                author_name: profileData ? 
                  `${profileData.first_name} ${profileData.last_name}` : 
                  (userData?.email ? userData.email.split('@')[0] : 'Anonymous')
              };
            }
            return { ...post, author_name: 'Anonymous' };
          })
        );
        
        setPosts(postsWithAuthors || []);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        // If there's an error, try to fetch just the basic posts
        try {
          const { data } = await supabase
            .from('blog_posts')
            .select('*')
            .order('created_at', { ascending: false });
          
          setPosts(data || []);
        } catch (fallbackError) {
          console.error('Fallback error:', fallbackError);
        }
      } finally {
        setLoading(false);
      }
    };

    // Check if user has blog admin rights
    const checkUserPermissions = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user) return;

        const { data, error } = await supabase
          .from('blog_admins')
          .select('*')
          .eq('user_id', session.user.id)
          .single();

        if (data && !error) {
          setUserIsAdmin(true);
        }
      } catch (error) {
        console.error('Error checking user permissions:', error);
      }
    };

    fetchPosts();
    checkUserPermissions();
  }, []);

  const navigateToPost = (postId) => {
    navigate(`/blog/${postId}`);
  };

  const navigateToAdmin = () => {
    navigate('/blog/admin');
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-slate-800">Blog</h1>
        {userIsAdmin && (
          <Button onClick={navigateToAdmin}>Manage Blog</Button>
        )}
      </div>

      {loading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      ) : posts.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {posts.map((post) => (
            <div 
              key={post.id} 
              className="bg-white border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-200 cursor-pointer"
              onClick={() => navigateToPost(post.id)}
            >
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2 text-slate-800">{post.title}</h2>
                <p className="text-slate-600 mb-4">{post.subtitle}</p>
                <div className="flex flex-col space-y-2 text-sm text-slate-500">
                  <p className="font-medium">
                    {post.author_name ? `By ${post.author_name}` : 'Anonymous'}
                  </p>
                  <p>
                    {new Date(post.created_at).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12">
          <p className="text-slate-600">No blog posts available yet.</p>
        </div>
      )}
    </div>
  );
};

const Blog = () => (
  <CustomCanvas>
    <BlogPage />
  </CustomCanvas>
);

export default Blog;
