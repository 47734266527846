import {React, useState, useEffect, useMemo} from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//Common Pages
import Hero from '@/pages/common/Hero'
import Home from  '@/pages/common/Home'
import About from '@/pages/common/About'
import Blog from '@/pages/common/Blog'
import BlogPost from '@/pages/common/BlogPost'
import BlogAdmin from '@/pages/common/BlogAdmin'
import BlogForm from '@/pages/common/BlogForm'

//HCP Pages
import HCPHome from '@/pages/hcp/Home'
import HCPDashboard from '@/pages/hcp/Dashboard'
import Transactions from '@/pages/hcp/Transactions'
import Services from '@/pages/hcp/Services'
import Appointments from '@/pages/hcp/Appointments'
import Patients from '@/pages/hcp/Patients'

//Client Pages
import Billing from '@/pages/client/Billing'
import SearchResults from '@/pages/client/SearchResults'
import SearchPage from '@/pages/client/Search'

//Auth Pages
import ClientSignUp from '@/pages/auth/ClientSignUp'
import SignIn from '@/pages/auth/SignIn'
import HCPSignUp from '@/pages/auth/HCPSignUp'

//Authentication
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { SessionContextProvider } from '@supabase/auth-helpers-react'
import {supabase} from "@/lib/supabase"
import ProtectedRoute from '@/components/auth/ProtectedRoute'
import { SignUpTypeSelector } from './components/auth/SignUpTypeSelector'

const getHomePage = () => {
  // return <SearchPage/>
  // return (process.env.REACT_APP_IS_PROD === 'true' ? <Home/> : <SearchPage/>)
  return <Home/>
}


function App() {
  const [session, setSession] = useState(null)

  useEffect(() => {
    // Initial session check
    const initSession = async () => {
      const { data: { session: initialSession } } = await supabase.auth.getSession()
      setSession(initialSession)
    }
    initSession()

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      // Only update if the session actually changed
      setSession(prev => {
        if (prev?.user?.id === session?.user?.id) return prev
        return session
      })
    })

    return () => subscription.unsubscribe()
  }, [])

  // Memoize the routes to prevent unnecessary re-renders
  const protectedRoutes = useMemo(() => ({
    dashboard: <ProtectedRoute><HCPHome/></ProtectedRoute>,
    transactions: <ProtectedRoute><Transactions/></ProtectedRoute>,
    services: <ProtectedRoute><Services/></ProtectedRoute>,
    appointments: <ProtectedRoute><Appointments/></ProtectedRoute>,
    patients: <ProtectedRoute><Patients/></ProtectedRoute>
  }), [])

  return (
    <SessionContextProvider supabaseClient={supabase}>
      <Router>
        <Routes>
          <Route path="/" element={getHomePage()} />
          {/* <Route path="/" element={<Home/>} /> */}
          <Route path="/signup" element={<SignUpTypeSelector />} />
          <Route path="/signup/patient" element={<ClientSignUp/>} />
          <Route path="/signup/provider" element={<HCPSignUp/>} />
          <Route path="/login" element={<SignIn/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/dashboard" element={protectedRoutes.dashboard} />
          <Route path="/transactions" element={protectedRoutes.transactions} />
          <Route path="/services" element={protectedRoutes.services} />
          <Route path="/appointments" element={protectedRoutes.appointments} />
          <Route path="/patients" element={protectedRoutes.patients} />
          <Route path="/billing" element={<Billing/>} />
          <Route path="/search" element={<SearchPage/>} />
          <Route path="/results" element={<SearchResults/>} />
          
          {/* Blog Routes */}
          <Route path="/blog" element={<Blog/>} />
          <Route path="/blog/:id" element={<BlogPost/>} />
          <Route path="/blog/admin" element={<BlogAdmin/>} />
          <Route path="/blog/admin/create" element={<BlogForm/>} />
          <Route path="/blog/admin/edit/:id" element={<BlogForm/>} />
        </Routes>
      </Router>
    </SessionContextProvider>
  );
}

export default App;
