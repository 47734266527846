import { useState, useEffect } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { format } from "date-fns"
import { supabase } from "@/lib/supabase"
import { useToast } from "@/components/ui/use-toast"
import { Skeleton } from "@/components/ui/skeleton"

const TableSkeleton = () => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead>User</TableHead>
        <TableHead>Date</TableHead>
        <TableHead>Service</TableHead>
        <TableHead className="text-right">Amount</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {[1, 2, 3].map((i) => (
        <TableRow key={i}>
          <TableCell><Skeleton className="h-8 w-[200px]" /></TableCell>
          <TableCell><Skeleton className="h-4 w-[120px]" /></TableCell>
          <TableCell><Skeleton className="h-4 w-[150px]" /></TableCell>
          <TableCell className="text-right"><Skeleton className="h-4 w-[80px] ml-auto" /></TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

const NoTransactions = () => (
  <div className="flex rounded-md justify-center items-center m-8 min-h-[75px] bg-muted/40">
    <p className="text-xl tracking-tight text-slate-600">No transactions found</p>
  </div>
)

export default function TransactionsTable() {
  const [transactions, setTransactions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [providerId, setProviderId] = useState(null)
  const { toast } = useToast()

  useEffect(() => {
    fetchProviderId()
  }, [])

  useEffect(() => {
    if (providerId) {
      fetchTransactions()
    }
  }, [providerId])

  const fetchProviderId = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) throw new Error('No user found')

      const { data: providerData, error: providerError } = await supabase
        .from('providers_profile')
        .select('id')
        .eq('user_id', user.id)
        .single()

      if (providerError) throw providerError
      if (!providerData) throw new Error('No provider profile found')

      setProviderId(providerData.id)
    } catch (error) {
      console.error('Error fetching provider ID:', error)
    }
  }

  const fetchTransactions = async () => {
    try {
      setIsLoading(true)
      const { data, error } = await supabase
        .from('transactions')
        .select(`
          id,
          created_at,
          amount,
          service:services(name),
          client:clients_profile(first_name, last_name, avatar_url)
        `)
        .eq('provider_id', providerId)
        .order('created_at', { ascending: false })

      if (error) throw error
      setTransactions(data || [])
    } catch (error) {
      console.error('Error fetching transactions:', error)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) return <TableSkeleton />
  if (transactions.length === 0) return <NoTransactions />

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Client</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Service</TableHead>
          <TableHead className="text-right">Amount</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {transactions.map((transaction) => (
          <TableRow key={transaction.id}>
            <TableCell className="font-medium">
              <div className="flex items-center space-x-3">
                <Avatar>
                  <AvatarImage src={transaction.client?.avatar_url} alt={`${transaction.client?.first_name} ${transaction.client?.last_name}`} />
                  <AvatarFallback>
                    {getInitials(`${transaction.client?.first_name} ${transaction.client?.last_name}`)}
                  </AvatarFallback>
                </Avatar>
                <span>{`${transaction.client?.first_name} ${transaction.client?.last_name}`}</span>
              </div>
            </TableCell>
            <TableCell>{format(new Date(transaction.created_at), 'PPP')}</TableCell>
            <TableCell>{transaction.service?.name}</TableCell>
            <TableCell className="text-right">${transaction.amount}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

function getInitials(name) {
  return name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase()
}
