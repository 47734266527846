import React from 'react';
import { PersonalInfoCard } from '@/components/dashboard/PersonalInfoCard';
import { ServicesCard } from '@/components/dashboard/ServicesCard';
import { AppointmentsCard } from '@/components/dashboard/AppointmentsCard';
import { StatisticsCard } from '@/components/dashboard/StatisticsCard';

function DashboardGraphs({ selectedOption }) {
  const graphs = [
    PersonalInfoCard,
    ServicesCard, 
    AppointmentsCard,
    StatisticsCard
  ]

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {graphs.map((Graph, index) => (
        <Graph key={index} />
      ))}
    </div>
  );
}

export default DashboardGraphs;
