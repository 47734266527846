import React, { useState } from 'react'
import { CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Button } from "@/components/ui/button"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import { PlusCircle, Trash2, PencilIcon, CheckIcon } from 'lucide-react'

const ServiceTableRow = ({ service, index, isEditing, handleServiceChange, removeService, onEdit }) => {
  const handleCheckClick = () => {
    onEdit(-1);
  };

  if (isEditing) {
    return (
      <TableRow>
        <TableCell>
          <Input
            id={`serviceName-${index}`}
            name="name"
            value={service.name || ''}
            onChange={(e) => handleServiceChange(index, e)}
            required
          />
        </TableCell>
        <TableCell className="max-w-[125px]">
          <Input
            id={`servicePrice-${index}`}
            name="price"
            type="number"
            inputMode="decimal"
            pattern="[0-9]*"
            min="0"
            step="0.01"
            value={service.price || ''}
            onChange={(e) => handleServiceChange(index, e)}
            required
          />
        </TableCell>
        <TableCell>
          <Select 
            value={service.duration || ''}
            onValueChange={(value) => handleServiceChange(index, { target: { name: 'duration', value }})}>
            <SelectTrigger>
              <SelectValue placeholder="Select duration" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="30">30 mins</SelectItem>
              <SelectItem value="60">60 mins</SelectItem>
              <SelectItem value="90">90 mins</SelectItem>
              <SelectItem value="120">120 mins</SelectItem>
            </SelectContent>
          </Select>
        </TableCell>
        <TableCell>
          <div className="flex gap-1">
            <Button 
              variant="outline" 
              size="icon" 
              onClick={handleCheckClick}
            >
              <CheckIcon className="h-4 w-4" />
            </Button>
            <Button 
              variant="destructive" 
              size="icon" 
              onClick={() => removeService(index)}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{service.name}</TableCell>
      <TableCell>${service.price}</TableCell>
      <TableCell>{service.duration} mins</TableCell>
      <TableCell>
        <div className="flex gap-1 justify-end">
          <Button variant="ghost" size="icon" onClick={() => onEdit(index)}>
            <PencilIcon className="h-4 w-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default function ServicesAndPricing({ formData, handleServiceChange, addService }) {
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isNewRow, setIsNewRow] = useState(false);

  const handleAddService = () => {
    addService();
    setEditingIndex(formData.services.length);
    setIsNewRow(true);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setIsNewRow(false);
  };

  const removeService = (index) => {
    // Create a new array without the service at the specified index
    const updatedServices = [...formData.services];
    updatedServices.splice(index, 1);
    formData.services = updatedServices;
    
    // Reset editing state if we're deleting the row being edited
    if (index === editingIndex) {
      setEditingIndex(-1);
    }
    setIsNewRow(false);
  };

  return (
    <>
      <CardHeader className="bg-60/40">
        <CardTitle>Step 3</CardTitle>
        <CardDescription>Add Services</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6 pt-6">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[40%]">Service Name</TableHead>
              <TableHead>Price</TableHead>
              <TableHead>Time</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {formData.services.map((service, index) => (
              <ServiceTableRow
                key={index}
                service={service}
                index={index}
                isEditing={index === editingIndex || (isNewRow && index === formData.services.length - 1)}
                handleServiceChange={handleServiceChange}
                removeService={removeService}
                onEdit={handleEdit}
              />
            ))}
          </TableBody>
        </Table>
        <div className="flex justify-center">
          <Button onClick={handleAddService} variant="outline" className="w-full sm:w-auto">
            <PlusCircle className="h-4 w-4 mr-2" />
            Add New Service
          </Button>
        </div>
      </CardContent>
    </>
  );
}
