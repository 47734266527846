import { useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"

import DaySchedule from '@/components/hcp/DaySchedule'

const getDaysInMonth = (year, month) => {
  console.error('getDaysInMonth called with:', year, month);
  return new Date(year, month + 1, 0).getDate()
}

const getFirstDayOfMonth = (year, month) => {
  console.error('getFirstDayOfMonth called with:', year, month);
  return new Date(year, month, 1).getDay()
}

export default function Schedule() {
  console.error('Schedule component rendered');
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(null)

  const year = currentDate.getFullYear()
  const month = currentDate.getMonth()

  console.error('Current date:', year, month);

  const daysInMonth = getDaysInMonth(year, month)
  const firstDayOfMonth = getFirstDayOfMonth(year, month)

  console.error('daysInMonth:', daysInMonth, 'firstDayOfMonth:', firstDayOfMonth);

  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1)
  const emptyDays = Array.from({ length: firstDayOfMonth || 0 }, (_, i) => i)

  console.error('days array length:', days.length, 'emptyDays array length:', emptyDays.length);

  const appointments = {
    "2023-06-15": [{ title: "Meeting with John", time: "10:00 AM" }],
    "2023-06-20": [{ title: "Dentist Appointment", time: "2:00 PM" }],
  }

  const navigateMonth = (direction) => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate)
      if (direction === 'prev') {
        newDate.setMonth(newDate.getMonth() - 1)
      } else {
        newDate.setMonth(newDate.getMonth() + 1)
      }
      return newDate
    })
  }

  return (
    <div className="container mx-auto p-4 max-w-[830px]">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
          {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </h2>
        <div className="flex gap-2">
          <Button onClick={() => navigateMonth('prev')} variant="outline" size="icon">
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button onClick={() => navigateMonth('next')} variant="outline" size="icon">
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-2">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="text-center font-semibold p-2">{day}</div>
        ))}
        {emptyDays.map(day => (
          <div key={`empty-${day}`} className="p-2"></div>
        ))}
        {days.map(day => {
          const date = new Date(year, month, day)
          const dateString = date.toISOString().split('T')[0]
          const hasAppointments = appointments[dateString]?.length > 0
          return (
            <Dialog key={day}>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  className={`h-24 p-0 relative ${hasAppointments ? 'bg-blue-100 hover:bg-blue-200' : ''}`}
                  onClick={() => setSelectedDate(date)}
                >
                  <span className="absolute top-1 right-2 text-sm font-semibold">{day}</span>
                  {hasAppointments && (
                    <span className="absolute bottom-1 left-2 text-xs text-blue-600">
                      {appointments[dateString].length} appointment(s)
                    </span>
                  )}
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>
                    {selectedDate?.toLocaleDateString('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                  </DialogTitle>
                </DialogHeader>
                <Card>
                  <CardHeader>
                    <CardTitle>Appointments</CardTitle>
                    <CardDescription>Your schedule for the day</CardDescription>
                  </CardHeader>
                  <CardContent>
                    {selectedDate ? (
                      <DaySchedule />
                    ) : (
                      <p>No appointments scheduled for this day.</p>
                    )}
                  </CardContent>
                </Card>
              </DialogContent>
            </Dialog>
          )
        })}
      </div>
    </div>
  )
}
