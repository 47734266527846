import React from 'react';
import { Link } from 'react-router-dom';
import MateoLogo from "@/assets/logo.png";
import AccountDropdown from '@/components/common/AccountDropdown';

const NavBar = () => {
  return (
    <header className="flex items-center justify-between p-4 bg-background border-b">
      <Link to="/" className="flex items-center text-2xl font-bold text-slate-700">
        <img className="w-14 h-14 object-contain" src={MateoLogo} alt="Mateos"/>
      </Link>
      
      <AccountDropdown />
    </header>
  );
};

export default NavBar;
