import React, { useState, useEffect } from "react"
import { useLocation } from 'react-router-dom';
import BillingBox from "@/components/client/BillingBox"
import NavBar from "@/components/layout/NavBar"
import OrderDetails from "@/components/client/OrderDetails"
import { supabase } from "@/lib/supabase"

export default function Billing() {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const providerId = searchParams.get('provider_id');
        const serviceId = searchParams.get('service_id');
        const date = searchParams.get('date');
        const time = searchParams.get('time');

        if (!providerId || !serviceId) {
          throw new Error('Missing required parameters');
        }

        // Fetch provider details
        const { data: providerData, error: providerError } = await supabase
          .from('providers')
          .select(`
            id,
            profile_id,
            license,
            specialization,
            profiles (
              first_name,
              last_name
            ),
            addresses!addresses_provider_id_fkey (
              street,
              city,
              state,
              zip_code,
              is_primary
            )
          `)
          .eq('id', providerId)
          .single();

        if (providerError) throw providerError;

        // Fetch service details
        const { data: serviceData, error: serviceError } = await supabase
          .from('services')
          .select('*')
          .eq('id', serviceId)
          .single();

        if (serviceError) throw serviceError;

        // Calculate fees and total
        const serviceFee = serviceData.price * 0.05; // 5% service fee
        const subtotal = serviceData.price + serviceFee;
        const tax = subtotal * 0.08; // 8% tax
        const total = subtotal + tax;

        // Get primary address or first address
        const primaryAddress = providerData.addresses?.find(addr => addr.is_primary) || providerData.addresses?.[0];

        // Construct order object
        const orderDetails = {
          id: `ORD-${Date.now()}`,
          date: new Date(date).toLocaleDateString(),
          time: time,
          item: {
            name: serviceData.name,
            price: serviceData.price
          },
          doctor: `Dr. ${providerData.profiles.first_name} ${providerData.profiles.last_name}`,
          serviceFee: serviceFee,
          tax: tax,
          total: total,
          appointment: {
            address: primaryAddress?.street || '',
            cityStateZip: `${primaryAddress?.city || ''}, ${primaryAddress?.state || ''} ${primaryAddress?.zip_code || ''}`
          },
          customer: {
            name: "To be filled",
            email: "To be filled",
            phone: "To be filled"
          },
          payment: {
            cardType: "",
            cardNumber: ""
          }
        };

        setOrder(orderDetails);
      } catch (error) {
        console.error('Error fetching order details:', error);
        // Handle error appropriately
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [location.search]);

  if (loading) {
    return (
      <>
        <NavBar />
        <div className="flex justify-center">
          <div className="w-full max-w-6xl p-4 md:p-8">
            <div>Loading...</div>
          </div>
        </div>
      </>
    );
  }

  if (!order) {
    return (
      <>
        <NavBar />
        <div className="flex justify-center">
          <div className="w-full max-w-6xl p-4 md:p-8">
            <div>Error loading order details</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <NavBar />
      <div className="flex justify-center">
        <div className="w-full max-w-6xl p-4 md:p-8">
          <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-8">
            <div className="w-full md:w-1/2">
              <OrderDetails order={order} />
            </div>
            <div className="w-full md:w-1/2">
              <BillingBox price={order.total.toFixed(2)} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
