import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';

const ProtectedBlogRoute = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateBlogAdmin = async () => {
      try {
        // First check for active session
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user) {
          throw new Error('No authenticated user');
        }

        // Check if user is in the blog_admins table
        const { data, error } = await supabase
          .from('blog_admins')
          .select('*')
          .eq('user_id', session.user.id)
          .single();

        if (error || !data) {
          throw new Error('User is not authorized to access blog administration');
        }

        setIsAuthorized(true);
      } catch (error) {
        console.error('Blog authorization error:', error);
        setIsAuthorized(false);
      } finally {
        setLoading(false);
      }
    };

    validateBlogAdmin();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!isAuthorized) {
    return <Navigate to="/blog" replace />;
  }

  return children;
};

export default ProtectedBlogRoute; 