import React, { useState, useEffect, useCallback } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Pencil, Trash2, Plus } from 'lucide-react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { supabase } from "@/lib/supabase"
import { useToast } from "@/components/ui/use-toast"
import { Skeleton } from "@/components/ui/skeleton"
import { storeAuthData, getStoredAuthData } from '@/lib/authStore'

const NoServices = () => (
  <div className="flex rounded-md justify-center items-center m-8 min-h-[75px] bg-muted/40">
    <p className="text-xl tracking-tight text-slate-600">No services added</p>
  </div>  
)

const TableSkeleton = () => (
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead>Service Name</TableHead>
        <TableHead>Price</TableHead>
        <TableHead>Duration</TableHead>
        <TableHead className="text-right">Actions</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {[1, 2, 3].map((i) => (
        <TableRow key={i}>
          <TableCell><Skeleton className="h-4 w-[200px]" /></TableCell>
          <TableCell><Skeleton className="h-4 w-[80px]" /></TableCell>
          <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
          <TableCell className="text-right">
            <Skeleton className="h-8 w-[80px] ml-auto" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

const formatSecondDuration = (seconds) => {
  //Show the duration in mins
  const mins = Math.floor(seconds / 60)
  return `${mins} mins`
}

const formatDuration = (intervalString) => {
  // Handle ISO format "HH:MM:SS"
  const [hours, minutes] = intervalString.split(':');
  
  // Convert to numbers and handle any parsing errors
  const hrs = parseInt(hours, 10) || 0;
  const mins = parseInt(minutes, 10) || 0;
  
  // Build display string
  const parts = [];
  
  if (hrs > 0) {
    parts.push(`${hrs} ${hrs === 1 ? 'hr' : 'hrs'}`);
  }
  
  if (mins > 0) {
    parts.push(`${mins} ${mins === 1 ? 'min' : 'mins'}`);
  }
  
  // Handle edge case of zero duration
  if (parts.length === 0) {
    return '0 mins';
  }
  
  return parts.join(' ');
};

export default function Services() {
  const [services, setServices] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [editingService, setEditingService] = useState(null)
  const [providerId, setProviderId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    duration: '',
  })
  const { toast } = useToast()

  // Memoize the fetchServices function
  const fetchServices = useCallback(async () => {
    try {
      setIsLoading(true)
      const { providerId: storedProviderId } = getStoredAuthData()
      if (!storedProviderId) {
        throw new Error('No provider ID found')
      }

      const { data, error } = await supabase
        .from('services')
        .select('*')
        .eq('provider_id', storedProviderId)
        .order('name')

      if (error) throw error
      setServices(data || [])
    } catch (error) {
      console.error('Error fetching services:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load services"
      })
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    fetchServices()
  }, [fetchServices])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { providerId: storedProviderId } = getStoredAuthData()
      if (!storedProviderId) {
        throw new Error('No provider ID found')
      }

      const serviceData = {
        ...formData,
        provider_id: storedProviderId,
        price: parseFloat(formData.price),
        duration: parseInt(formData.duration),
      }

      let error
      if (editingService) {
        const { error: updateError } = await supabase
          .from('services')
          .update(serviceData)
          .eq('id', editingService.id)
          .eq('provider_id', storedProviderId)
        error = updateError
      } else {
        const { error: insertError } = await supabase
          .from('services')
          .insert([serviceData])
        error = insertError
      }

      if (error) throw error

      toast({
        title: "Success",
        description: editingService ? 'Service updated' : 'Service added'
      })
      setIsDialogOpen(false)
      setEditingService(null)
      setFormData({ name: '', price: '', duration: '' })
      fetchServices() // This will now work
    } catch (error) {
      console.error('Error saving service:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to save service"
      })
    }
  }

  const handleDelete = async (serviceId) => {
    try {
      const { providerId: storedProviderId } = getStoredAuthData()
      if (!storedProviderId) {
        throw new Error('No provider ID found')
      }

      const { error } = await supabase
        .from('services')
        .delete()
        .eq('id', serviceId)
        .eq('provider_id', storedProviderId)

      if (error) throw error

      toast({
        title: "Success",
        description: "Service deleted"
      })
      fetchServices()
    } catch (error) {
      console.error('Error deleting service:', error)
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete service"
      })
    }
  }

  const handleEdit = (service) => {
    setEditingService(service)
    // Convert the HH:MM:SS format to seconds for the select dropdown
    const durationInSeconds = service.duration.split(':')
      .reduce((acc, time) => (60 * acc) + parseInt(time), 0);
    
    const newFormData = {
      name: service.name,
      price: service.price.toString(),
      duration: durationInSeconds.toString(), // This will now match the Select values
    }
    console.log('Opening edit modal with formData:', newFormData)
    setFormData(newFormData)
    setIsDialogOpen(true)
  }

  return (
    <div className="container mx-auto p-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Services</CardTitle>
          <Button onClick={() => {
            setEditingService(null)
            const newFormData = { name: '', price: '', duration: '' }
            console.log('Opening add modal with formData:', newFormData)
            setFormData(newFormData)
            setIsDialogOpen(true)
          }}>
            <Plus className="mr-2 h-4 w-4" />
            Add Service
          </Button>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <TableSkeleton />
          ) : services.length === 0 ? (
            <NoServices />
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Service Name</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Duration</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {services.map((service) => (
                  <TableRow key={service.id}>
                    <TableCell>{service.name}</TableCell>
                    <TableCell>${service.price}</TableCell>
                    <TableCell>{formatDuration(service.duration)}</TableCell>
                    <TableCell className="text-right">
                      <Button variant="ghost" size="icon" onClick={() => handleEdit(service)}>
                        <Pencil className="h-4 w-4" />
                      </Button>
                      <Button variant="ghost" size="icon" onClick={() => handleDelete(service.id)}>
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{editingService ? 'Edit Service' : 'Add New Service'}</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div>
                <Label htmlFor="name">Service Name</Label>
                <Input
                  id="name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                />
              </div>
              <div>
                <Label htmlFor="price">Price ($)</Label>
                <Input
                  id="price"
                  type="number"
                  min="0"
                  step="0.01"
                  value={formData.price}
                  onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                  required
                />
              </div>
              <div>
                <Label htmlFor="duration">Duration (minutes)</Label>
                <Select 
                  value={formData.duration} 
                  onValueChange={(value) => setFormData({ ...formData, duration: value })}
                  required
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select duration"/>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="1800">30 mins</SelectItem>
                    <SelectItem value="3600">60 mins</SelectItem>
                    <SelectItem value="5400">90 mins</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <DialogFooter className="mt-6">
              <Button type="submit">
                {editingService ? 'Update' : 'Add'} Service
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
