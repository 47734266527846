import React, { useState } from "react"
import { useNavigate } from 'react-router-dom'
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card"
import { ChevronDown, ChevronUp } from "lucide-react"
import { useToast } from "@/components/ui/use-toast"
import CreditCardInput from "@/components/client/CreditCardInput"

const BillingAddressSection = ({ formData, setFormData }) => {
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <div>
      <Button
        type="button"
        variant="ghost"
        className="flex w-full justify-between px-2 my-2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>Billing Address</span>
        {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
      </Button>
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-[1000px]' : 'max-h-0'}`}>
        <div className="space-y-2 py-2">
          <Input 
            placeholder="Street Address" 
            value={formData.street}
            onChange={(e) => setFormData({ ...formData, street: e.target.value })}
          />
          <Input 
            placeholder="City" 
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
          />
          <Input 
            placeholder="State" 
            value={formData.state}
            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
          />
          <Input 
            placeholder="Country" 
            value={formData.country}
            onChange={(e) => setFormData({ ...formData, country: e.target.value })}
          />
        </div>
      </div>
    </div>
  )
}

const BillingBox = ({ price }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    cardNumber: '',
    cvc: '',
    expiryMonth: '',
    expiryYear: '',
    zipCode: '',
    street: '',
    city: '',
    state: '',
    country: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!formData.name || !formData.email || !formData.cardNumber || !formData.cvc || 
        !formData.expiryMonth || !formData.expiryYear || !formData.zipCode) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please fill in all required fields"
      });
      return;
    }

    try {
      // Here you would typically:
      // 1. Process the payment with a payment provider (e.g., Stripe)
      // 2. Create the appointment in your database
      // 3. Send confirmation emails
      
      // For now, we'll just show a success message and redirect
      toast({
        title: "Success",
        description: "Payment processed successfully"
      });

      // Redirect to confirmation page or dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error processing payment:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to process payment. Please try again."
      });
    }
  };

  return (
    <Card className="w-full max-w-md shadow-md">
      <CardHeader>
        <CardTitle>Billing</CardTitle>
        <CardDescription>Complete your payment to continue.</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input 
              id="name" 
              placeholder="John Doe" 
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input 
              id="email" 
              type="email" 
              placeholder="john@example.com" 
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="phone">Phone</Label>
            <Input 
              id="phone" 
              type="tel" 
              placeholder="+1 (555) 123-4567" 
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="card">Card Information</Label>
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <CreditCardInput 
                  value={formData.cardNumber}
                  onChange={(value) => setFormData({ ...formData, cardNumber: value })}
                />
              </div>
              <div>
                <Input 
                  maxLength={3} 
                  id="cvc" 
                  placeholder="CVC" 
                  value={formData.cvc}
                  onChange={(e) => setFormData({ ...formData, cvc: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="expiry">Expiry</Label>
              <div className="grid grid-cols-2 gap-2">
                <Select 
                  value={formData.expiryMonth}
                  onValueChange={(value) => setFormData({ ...formData, expiryMonth: value })}
                >
                  <SelectTrigger id="expiry-month">
                    <SelectValue placeholder="Month" />
                  </SelectTrigger>
                  <SelectContent>
                    {[...Array(12)].map((_, i) => (
                      <SelectItem key={i} value={(i + 1).toString().padStart(2, '0')}>
                        {(i + 1).toString().padStart(2, '0')}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Select
                  value={formData.expiryYear}
                  onValueChange={(value) => setFormData({ ...formData, expiryYear: value })}
                >
                  <SelectTrigger id="expiry-year">
                    <SelectValue placeholder="Year" />
                  </SelectTrigger>
                  <SelectContent>
                    {[...Array(8)].map((_, i) => {
                      const year = new Date().getFullYear() + i;
                      return (
                        <SelectItem key={year} value={year.toString()}>
                          {year}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="zip">Zip Code</Label>
              <Input 
                id="zip" 
                placeholder="90210" 
                value={formData.zipCode}
                onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
                required
              />
            </div>
          </div>
          <BillingAddressSection formData={formData} setFormData={setFormData} />
          <Button type="submit" className="w-full">
            Pay ${price}
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

export default BillingBox
