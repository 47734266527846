import React from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Stethoscope } from 'lucide-react';
import { cn } from '../../lib/utils';

const SelectionBox = ({ title, icon, onClick }) => (
  <div
    onClick={onClick}
    className={cn(
      'flex flex-col items-center justify-center',
      'w-64 h-64 p-6 m-4',
      'bg-white rounded-lg shadow-lg',
      'border-2 border-gray-200',
      'cursor-pointer transition-all duration-200',
      'hover:border-blue-500 hover:shadow-xl hover:scale-105'
    )}
  >
    <div className="text-gray-600 mb-4 text-5xl">
      {icon}
    </div>
    <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
  </div>
);

export const SignUpTypeSelector = () => {
  const navigate = useNavigate();

  const handlePatientClick = () => {
    navigate('/signup/patient');
  };

  const handleProviderClick = () => {
    navigate('/signup/provider');
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Choose Account Type</h1>
      <div className="flex flex-wrap justify-center items-center gap-8">
        <SelectionBox
          title="Patient"
          icon={<User className="w-16 h-16" />}
          onClick={handlePatientClick}
        />
        <SelectionBox
          title="Provider"
          icon={<Stethoscope className="w-16 h-16" />}
          onClick={handleProviderClick}
        />
      </div>
    </div>
  );
}; 