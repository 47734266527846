import React, { useState } from 'react';
import { ChevronsUpDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

const AddressSearch = ({ onAddressSelect, className, scope = 'cities' }) => {
  const [open, setOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState('');

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: scope === 'streets' ? ['address'] : ['(cities)']
    },
    debounce: 300,
  });

  const extractAddressComponents = (result) => {
    const addressComponents = {};
    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',          // city
      administrative_area_level_1: 'short_name',  // state
      postal_code: 'short_name',      // zip code
    };

    // Extract each component of the address from the place details
    for (const component of result.address_components) {
      const addressType = component.types[0];
      if (componentForm[addressType]) {
        const val = component[componentForm[addressType]];
        addressComponents[addressType] = val;
      }
    }

    // Format the street address
    const streetAddress = addressComponents.street_number && addressComponents.route
      ? `${addressComponents.street_number} ${addressComponents.route}`
      : addressComponents.route || '';

    return {
      street: streetAddress,
      city: addressComponents.locality || '',
      state: addressComponents.administrative_area_level_1 || '',
      zipCode: addressComponents.postal_code || '',
    };
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    setSelectedAddress(address);
    setOpen(false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      
      // Extract address components
      const addressComponents = extractAddressComponents(results[0]);

      // Call the callback with all the address information
      onAddressSelect({
        fullAddress: address,
        coordinates: { lat, lng },
        ...addressComponents
      });
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-full justify-between ${className}`}
        >
          {selectedAddress || `Enter your address...`}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command className="w-full">
          <CommandInput
            placeholder="Search for an address..."
            value={value}
            disabled={!ready}
            onValueChange={setValue}
            className="w-full"
          />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {status === "OK" &&
                data.map(({ place_id, description }) => (
                  <CommandItem
                    key={place_id}
                    value={description}
                    onSelect={() => handleSelect(description)}
                  >
                    {description}
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default AddressSearch;
