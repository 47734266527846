import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import CustomCanvas from "@/components/common/CustomCanvas";

// Helper function to format blog content with line breaks and paragraphs
const formatBlogContent = (content) => {
  if (!content) return '';
  
  // First split the content by double line breaks (paragraphs)
  const paragraphs = content.split(/\n\s*\n/);
  
  // Then process each paragraph
  return paragraphs
    .map((paragraph, index, array) => {
      // Replace single line breaks with <br> tags
      const formattedParagraph = paragraph.replace(/\n/g, '<br>');
      
      // Add margin-bottom to each paragraph except the last one
      if (index === array.length - 1) {
        return `<p style="margin-bottom: 0;">${formattedParagraph}</p>`;
      }
      return `<p style="margin-bottom: 2.5rem;">${formattedParagraph}</p>`;
    })
    .join('');
};

// Blog content styles
const blogContentStyles = {
  paragraph: {
    fontFamily: 'Georgia, serif',
    color: '#333',
    lineHeight: 1.8,
    fontSize: '1.125rem',
    marginBottom: '2.5rem',
  },
  container: {
    fontFamily: 'Georgia, serif',
    color: '#333',
    lineHeight: 1.8,
  }
};

const BlogPostPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // Simplified query - just get blog post without joins
        const { data, error } = await supabase
          .from('blog_posts')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;
        
        // If we have an author, get their info from the profiles table
        if (data && data.author_id) {
          // Get profile info - this is our primary source for author name
          const { data: profileData } = await supabase
            .from('profiles')
            .select('first_name, last_name')
            .eq('user_id', data.author_id)
            .single();
          
          if (profileData && profileData.first_name && profileData.last_name) {
            setPost({
              ...data,
              author_name: `${profileData.first_name} ${profileData.last_name}`
            });
          } else {
            // Fallback to user email if profile data not available
            const { data: userData } = await supabase
              .from('users')
              .select('email')
              .eq('id', data.author_id)
              .single();
            
            setPost({
              ...data,
              author_name: userData?.email ? userData.email.split('@')[0] : 'Anonymous'
            });
          }
        } else {
          setPost({ ...data, author_name: 'Anonymous' });
        }
      } catch (error) {
        console.error('Error fetching blog post:', error);
        try {
          // Fallback to just getting the post without author info
          const { data } = await supabase
            .from('blog_posts')
            .select('*')
            .eq('id', id)
            .single();
          
          if (data) setPost({ ...data, author_name: 'Anonymous' });
        } catch (fallbackError) {
          console.error('Fallback error:', fallbackError);
        }
      } finally {
        setLoading(false);
      }
    };

    // Check if user has blog admin rights
    const checkUserPermissions = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session?.user) return;

        const { data, error } = await supabase
          .from('blog_admins')
          .select('*')
          .eq('user_id', session.user.id)
          .single();

        if (data && !error) {
          setUserIsAdmin(true);
        }
      } catch (error) {
        console.error('Error checking user permissions:', error);
      }
    };

    fetchPost();
    checkUserPermissions();
  }, [id]);

  const navigateToEdit = () => {
    navigate(`/blog/admin/edit/${id}`);
  };

  const navigateBack = () => {
    navigate('/blog');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="container mx-auto px-4 py-12 text-center">
        <h1 className="text-2xl font-semibold mb-4">Blog post not found</h1>
        <Button onClick={navigateBack}>Back to Blog</Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
      <div className="mb-6">
        <Button variant="outline" onClick={navigateBack} className="mb-6">
          &larr; Back to Blog
        </Button>
        {userIsAdmin && (
          <Button onClick={navigateToEdit} className="ml-4">
            Edit Post
          </Button>
        )}
      </div>

      <article className="prose lg:prose-xl max-w-none bg-white p-8 rounded-lg shadow-sm">
        <h1 className="text-4xl font-bold text-slate-800 mb-2">{post.title}</h1>
        <h2 className="text-2xl font-medium text-slate-600 mb-8">{post.subtitle}</h2>
        
        <div className="flex items-center text-sm text-slate-500 mb-8">
          <span className="font-medium mr-2">
            {post.author_name ? `By ${post.author_name}` : 'Anonymous'}
          </span>
          <span>• Published on {new Date(post.created_at).toLocaleDateString()}</span>
        </div>

        <div style={{
          ...blogContentStyles.container,
          padding: '0.5rem 0'
        }}>
          <div 
            dangerouslySetInnerHTML={{ 
              __html: formatBlogContent(post.content) 
            }} 
            style={{
              fontSize: '1.25rem', 
              fontFamily: 'Georgia, serif',
              lineHeight: '1.8'
            }}
          />
        </div>
      </article>
    </div>
  );
};

const BlogPost = () => (
  <CustomCanvas>
    <BlogPostPage />
  </CustomCanvas>
);

export default BlogPost; 