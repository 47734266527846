import React, { useState, useEffect, useMemo } from 'react';
import { SidebarProvider, Sidebar, SidebarContent, SidebarInset } from "@/components/ui/sidebar";
import Header from '@/components/hcp/Header';
import DoctorSidebar from '@/components/hcp/Sidebar';
import DashboardGraphs from '@/components/hcp/HomeScreenContent';
import MateosLogo from "@/assets/logo.png"
import { supabase } from "@/lib/supabase";
import { getStoredAuthData } from '@/lib/authStore';

//Components
import Appointments from "@/components/hcp/Appointments";
import Schedule from "@/components/common/Schedule"
import Transactions from "@/pages/hcp/Transactions";
import Billing from "@/pages/hcp/Billing";
import Profile from "@/pages/hcp/Profile";
import Services from '@/components/hcp/Services';

function Dashboard() {
  const [selectedOption, setSelectedOption] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  // Memoize the components
  const components = useMemo(() => ({
    '': <DashboardGraphs />,
    'appointments': <Appointments />,
    'calendar': <Schedule />,
    'services': <Services />,
    'transactions': <Transactions />,
    'billing': <Billing />,
    'profile': <Profile />
  }), []);

  useEffect(() => {
    const fetchProviderData = async () => {
      // First check stored data
      const { userId } = getStoredAuthData();
      if (!userId) return;

      try {
        // We only need one request to get both user and provider data
        const { data: providerData, error } = await supabase
          .from('providers_profile')
          .select('last_name, email')
          .eq('user_id', userId)
          .single();
        
        if (!error && providerData) {
          setLastName(providerData.last_name);
          setEmail(providerData.email);
        }
      } catch (error) {
        console.error("Error fetching provider data:", error);
      }
    };

    fetchProviderData();
  }, []);

  // Memoize the header component
  const headerComponent = useMemo(() => {
    if (!lastName || !email) {
      return (
        <div className="animate-pulse flex flex-col justify-center m-4">
          <div className="h-8 w-48 bg-gray-200 rounded mb-2"></div>
          <div className="h-4 w-32 bg-gray-200 rounded"></div>
        </div>
      );
    }
    return <Header doctorName={`Dr. ${lastName}`} email={email} />;
  }, [lastName, email]);

  return (
    <SidebarProvider>
      <div className="flex h-screen w-screen">
        <Sidebar className="bg-muted/50">
          <SidebarContent>
            {/* <SidebarHeader /> */}
            <DoctorSidebar onSelect={setSelectedOption} />
          </SidebarContent>
        </Sidebar>
        <SidebarInset className="flex flex-col">
          {headerComponent}
          <main className="flex-1 p-6 overflow-auto">
            {components[selectedOption]}
          </main>
        </SidebarInset>
      </div>
    </SidebarProvider>
  );
}

export default Dashboard;
