import React from "react"
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";

export default function ServicesButtonGroup({ services, selectedService, onServiceSelect, loading }) {
  if (loading) {
    return (
      <div className="mt-4">
        <h3 className="text-xl font-semibold mb-2">Services Available</h3>
        <div className="flex flex-wrap gap-2">
          <Skeleton className="h-8 w-24" />
          <Skeleton className="h-8 w-32" />
          <Skeleton className="h-8 w-28" />
        </div>
      </div>
    );
  }

  // Early return with an empty div if services is undefined or empty
  if (!services || services.length === 0) {
    return (
      <div className="mt-4">
        <h3 className="text-xl font-semibold mb-2">Services Available</h3>
        <p className="text-muted-foreground">No services available</p>
      </div>
    );
  }

  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Services Available</h3>
      <div className="flex flex-wrap gap-2">
        {services.map((service) => (
          <Button 
            key={service.id}
            variant={selectedService?.id === service.id ? "default" : "outline"}
            size="sm"
            className="mb-2"
            onClick={() => onServiceSelect(service)}
          >
            {service.name}
          </Button>
        ))}
      </div>
    </div>
  )
}