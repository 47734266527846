import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { LayoutGridIcon } from "lucide-react"
import { useAuth } from "@/hooks/useAuth"
import { useState, useEffect } from "react"
import { supabase } from "@/lib/supabase"

export function ServicesCard() {
  const { session } = useAuth()
  const [services, setServices] = useState([])

  useEffect(() => {
    const fetchServices = async () => {
      if (session?.user) {
        const { data, error } = await supabase
          .from("services")
          .select("*")
          .eq("provider_id", localStorage.getItem("mateo_provider_id"))

        if (!error && data) {
          setServices(data)
        }
      }
    }

    fetchServices()
  }, [session])

  const activeServices = services.filter(service => service.is_active).length
  const inactiveServices = services.filter(service => !service.is_active).length

  return (
    <Card className="h-[350px] relative">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle>Services</CardTitle>
        <Button variant="outline" size="icon" className="h-8 w-8">
          <LayoutGridIcon className="h-4 w-4" />
        </Button>
      </CardHeader>
      <CardContent>
        <div className="space-y-8 mt-4">
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground"># of active services</p>
            <p className="text-2xl font-semibold">{activeServices}</p>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground"># of inactive services</p>
            <p className="text-2xl font-semibold">{inactiveServices}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}