import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { CheckCircle2, MapPin,  Clock, DollarSign } from "lucide-react";

const WizardReviewPage = ({ formData }) => {
  const sampleData = {
    email: "johndoe@example.com",
    license: "LIC-123456",
    street: "123 Main St",
    city: "Springfield",
    state: "IL",
    zipCode: "62701",
    services: [
      { name: "Basic Consultation", price: "100", duration: "60" },
      { name: "Premium Service", price: "200", duration: "90" },
      { name: "Comprehensive Review", price: "300", duration: "120" }
    ]
  };

  const data = formData || sampleData;

  return (
    <Card className="w-full max-w-3xl mx-auto border-0 mb-2">
      <CardHeader className="bg-blue-50 dark:bg-blue-950 dark:to-blue-900">
        <div className="flex items-center space-x-2">
          <CheckCircle2 className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          <CardTitle className="text-2xl font-bold">Review Your Information</CardTitle>
        </div>
      </CardHeader>
      
      <CardContent className="space-y-8 p-6">
        <section className="space-y-4">
          <div className="flex items-center space-x-2">
            <h3 className="text-lg font-semibold">Account Information</h3>
            <Badge variant="secondary">Personal</Badge>
          </div>
          <div className="bg-slate-50 dark:bg-slate-900 p-4 rounded-lg">
            <p className="flex items-center space-x-2">
              <span className="text-slate-600 dark:text-slate-400">Email:</span>
              <span className="font-medium">{data.email}</span>
            </p>
          </div>
        </section>

        <Separator />
        
        <section className="space-y-4">
          <div className="flex items-center space-x-2">
            <h3 className="text-lg font-semibold">Professional Information</h3>
            <Badge variant="secondary">Business</Badge>
          </div>
          <div className="bg-slate-50 dark:bg-slate-900 p-4 rounded-lg space-y-3">
            <p className="flex items-center space-x-2">
              <span className="text-slate-600 dark:text-slate-400">License:</span>
              <span className="font-medium">{data.license}</span>
            </p>
            <p className="flex items-center space-x-2">
              <MapPin className="w-4 h-4 text-slate-600" />
              <span className="text-slate-600 dark:text-slate-400">Address:</span>
              <span className="font-medium">
                {data.street}, {data.city}, {data.state} {data.zipCode}
              </span>
            </p>
          </div>
        </section>

        <Separator />

        <section className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <h3 className="text-lg font-semibold">Services and Pricing</h3>
              <Badge variant="secondary">Services ({data.services.length})</Badge>
            </div>
          </div>
          <div className="bg-slate-50 dark:bg-slate-900 rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-slate-100 dark:bg-slate-800">
                    <th className="text-left py-3 px-4 font-semibold">Service Name</th>
                    <th className="text-left py-3 px-4 font-semibold">
                      <div className="flex items-center space-x-1">
                        <DollarSign className="w-4 h-4" />
                        <span>Price</span>
                      </div>
                    </th>
                    <th className="text-left py-3 px-4 font-semibold">
                      <div className="flex items-center space-x-1">
                        <Clock className="w-4 h-4" />
                        <span>Duration</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.services.map((service, index) => (
                    <tr 
                      key={index} 
                      className="border-b last:border-b-0 hover:bg-slate-100 dark:hover:bg-slate-800 transition-colors"
                    >
                      <td className="py-3 px-4">{service.name}</td>
                      <td className="py-3 px-4">${service.price}</td>
                      <td className="py-3 px-4">{service.duration} mins</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <Separator />

        <section className="space-y-4">
          <div className="flex items-center space-x-2">
            <h3 className="text-lg font-semibold">Summary</h3>
            <Badge variant="secondary">Overview</Badge>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-slate-50 dark:bg-slate-900 p-4 rounded-lg">
              <p className="text-slate-600 dark:text-slate-400">Total Services</p>
              <p className="text-2xl font-semibold">{data.services.length}</p>
            </div>
            <div className="bg-slate-50 dark:bg-slate-900 p-4 rounded-lg">
              <p className="text-slate-600 dark:text-slate-400">Price Range</p>
              <p className="text-2xl font-semibold">
                ${Math.min(...data.services.map(s => parseFloat(s.price)))} - $
                {Math.max(...data.services.map(s => parseFloat(s.price)))}
              </p>
            </div>
          </div>
        </section>
      </CardContent>
    </Card>
  );
};

export default WizardReviewPage;
