import React from 'react'
import { CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import Place from "@/components/common/Place"

export default function ProfessionalInfo({ formData, handleChange }) {
  // New function to handle address selection
  const handleAddressSelect = (addressData) => {
    // Simulate change events for each address component
    const addressFields = {
      street: addressData.street,
      city: addressData.city,
      state: addressData.state,
      zipCode: addressData.zipCode
    };

    // Update each field individually using the existing handleChange
    Object.entries(addressFields).forEach(([name, value]) => {
      handleChange({
        target: {
          name,
          value
        }
      });
    });
  };

  return (
    <>
      <CardHeader>
        <CardTitle>Step 2</CardTitle>
        <CardDescription>Professional Information</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <Label htmlFor="license">Physician's License</Label>
          <Input
            id="license"
            name="license"
            value={formData.license}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <Label>Address</Label>
          <Place 
            scope='streets'
            onAddressSelect={handleAddressSelect}
          />
        </div>
      </CardContent>
    </>
  )
}
